<script setup>
    import CustomElement from "@/components/modules/CustomElement.vue"

    import {defineProps, computed, reactive} from "vue";
    import SectionContainer from "../containers/SectionContainer.vue";

    const props = defineProps({
        section: {
            type: Object
        },
        currentStudio: {
            type: Object
        },
    IsheroBaner: {
        type: Object
    },
    });

    // Element sorting
    const elements = computed(() => {
        return props.section.moduleSectionElements.length > 0
            ? reactive(props.section.moduleSectionElements).sort((a, b) => a.element_order - b.element_order)
            : [];
    });
</script>
<template>
    <SectionContainer
        :widthSize="props.section.section_size"
        :padding="props.section.section_padding"
        :margin="props.section.section_margin"
        :class="[props.section.section_alignment ? props.section.section_alignment : '']"
    >
        <template v-if="elements.length > 0">
            <template v-for="(element, elementIndex) in elements" :key="elementIndex">
                <CustomElement
                        :element="element"
                        :currentStudio="currentStudio"
                        :IsheroBaner="IsheroBaner"
                />
            </template>
        </template>
    </SectionContainer>
</template>
