<script>
export default {
  data() {
    return {
      // other data properties if needed
    };
  },
  computed: {
    currentYear() {
      const now = new Date();
      return now.getFullYear();
    },
  },
};
</script>

<template>
  <div class="copyrights">© 2009-{{ currentYear }} all rights reserved. </div>
</template>
