/**
 * converts time string to 12- or 24-hour format and adds/removes am/pm when appropriate
 *
 * @param   {String} time - the time string to be converted
 * @param   {int?} format - the returned time format (default 24)
 * @returns {String} - newly formatted time string
 */
export const convertTime = (time, format = 24) => {
    if (typeof time !== "string") return "# incorrect time format #";

    if (!time) return;
    
    time = time.toLowerCase();
    let timeSplit = time.split(/[^0-9]/);
    let h = parseInt(timeSplit[0].slice(0, 2));
    let m = timeSplit[1].slice(0, 2);
    
    if (format === 24) {
      if (time.indexOf("pm") !== -1 && h < 12) {
        h += 12;
      }
      if (h > 23) {
        h -= 24;
      }
      h = ("0" + h).slice(-2);
      return h + ":" + m;
    }
    
    let suffix = (h >= 12 ? " pm" : " am");
  
    if (h > 12) {
      h -= 12;
    }
    return h + ":" + m + suffix;
  };
  
  /**
   * takes a time string and compares it to local machine time
   *
   * @param   {String} time - the time to compare the user's local time
   * @returns {int} - time difference in minutes
   */
  export const compareTimeToLocal = (time, timeZone) => {
    let h = parseInt(time.split(":")[0]);
    let m = parseInt(time.split(":")[1]);
  
    // current datetime string in location timezome
    let datetime_str = new Date().toLocaleString("en-US", {
      timeZone: timeZone,
    });
    // create new Date object
    let localTime = new Date(datetime_str);
  
    let hour = localTime.getHours();
    let minute = localTime.getMinutes();
  
    // calculate time difference in minutes (local time - time passed in)
    let timeDiff = (hour - h) * 60 + (minute - m);
  
    return timeDiff;
  };
  
  /**
   * takes a time string and compares it to another
   *
   * @param   {String} firstTime - the first time to compare
   * @param   {String} secondTime - the second time to compare
   * @returns {int} - time difference in minutes
   */
  export const compareTimes = (firstTime, secondTime) => {
    firstTime = convertTime(firstTime);
    secondTime = convertTime(secondTime);
    
    let h1 = parseInt(firstTime.split(":")[0]);
    let m1 = parseInt(firstTime.split(":")[1]);
    
    let h2 = parseInt(secondTime.split(":")[0]);
    let m2 = parseInt(secondTime.split(":")[1]);
  
    let time1 = ("0" + h1).slice(-2) + ":" + ("0" + m1).slice(-2);
    let time2 = ("0" + h2).slice(-2) + ":" + ("0" + m2).slice(-2);
  
    return time1 > time2;
  };
  
  /**
   * Adds the specified number of minutes to a starting timestamp
   *
   * @param   {String} time - the starting time (e.g., "12:30")
   * @param   {int} timeToAdd - number of minutes to add to starting time
   * @returns {String} - the new time in 24-hour format after adding the minutes passed in
   */
  export const addToTime = (time, timeToAdd) => {
    time = convertTime(time);
  
    let h = parseInt(time.split(":")[0]);
    let m = parseInt(time.split(":")[1]);
  
    m += timeToAdd;
  
    while (m > 60) {
      h += 1;
      m -= 60;
    }
  
    while (m < 0) {
      h -= 1;
      m += 60;
    }
    
    let hResult = ("0" + h).slice(-2);
    let mResult = ("0" + m).slice(-2);
  
    return convertTime(hResult + ":" + mResult);
  };
  