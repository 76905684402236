const API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
const CALLBACK_NAME = 'gmapsCallback';

let initialized = !!window.google;
let resolveInitPromise;
let rejectInitPromise;

const initPromise = new Promise((resolve, reject) => {
    resolveInitPromise = resolve;
    rejectInitPromise = reject;
});

export default function init() {
    if (initialized) return initPromise;

    initialized = true;
    window[CALLBACK_NAME] = () => resolveInitPromise(window.google);

    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&callback=${CALLBACK_NAME}`;
    script.onerror = rejectInitPromise;
    document.querySelector('head').appendChild(script);

    return initPromise;
}

// Function to initialize the map
export function initMap(mapElementId, center = { lat: -34.397, lng: 150.644 }, zoom = 8) {
    const map = new google.maps.Map(document.getElementById(mapElementId), {
        center: center,
        zoom: zoom,
    });
    return map;
}

// Function to initialize the Autocomplete
export function initAutocomplete(inputElementId) {
    const input = document.getElementById(inputElementId);
    if (input) {
        const autocomplete = new google.maps.places.Autocomplete(input);
        return autocomplete;
    } else {
        console.error("Input for Autocomplete not found.");
        return null;
    }
}
