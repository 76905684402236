<script setup>
  import { defineProps } from 'vue'

  const props = defineProps({
    src: String,
    width: {
      type: String,
      default: '1920',
    },
    height: {
      type: String,
      default: '1080',
    },
  })
</script>

<template>
  <div class="videoplayer-box">
    <video 
        :width="props.width"
        :height="props.height"
        autoplay
        loop
        muted
        playsinline
    >
        <source :src="props.src" type="video/mp4">
    </video>
  </div>
</template>