<script setup>
import {computed, defineProps, onMounted, ref} from "vue";
import {fetchStudioSchedule} from "@/mixins/api";
import handleSchedule from "../../utils/handleSchedule";
import { convertTime } from "../../utils/timeHandlers";
import Text from "./Text.vue";

const props = defineProps({
  currentStudio: {
    type: Object
  }
});

const schedule = ref([]);

// Current Studio Id
const studioId = computed(() => {
  return props?.currentStudio?.ID
      ? props?.currentStudio?.ID
      : 0;
});

const getStudioSchedule = () => {
  fetchStudioSchedule(studioId.value).then((response) => {
    schedule.value = handleSchedule(response);
  });
};

onMounted(() => {
  getStudioSchedule();
});

</script>
<template>
  <template v-if="currentStudio">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <template v-for="(schedules, index) in schedule" :key="index">
              <th>
                <span class="schedule-week-head">{{ index }}</span>
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="(weekday, index) in schedule" :key="index">
              <template v-for="(schedules, index) in weekday" :key="index">
                <div v-if="schedules.class_time" class="schedule-time-name">
                    <Text 
                      type="SPAN"
                      :text="schedules.class_name"
                      class="schedule-time-name-title"
                    />
                    <Text 
                      type="SPAN"
                      :text="convertTime(schedules.class_time, 12)"
                    />
                </div>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
</template>