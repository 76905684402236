<script setup>
    import { defineProps } from 'vue'

    const props = defineProps({
        type: String,
    })
</script>

<template>
    <div class="torn-paper-divider-1" v-if="props.type === 'BLUE_DIVIDER_TOP'"></div>
    <div class="torn-paper-divider-2" v-if="props.type === 'BLUE_DIVIDER_BOTTOM'"></div>
    <div class="torn-paper-divider-3" v-if="props.type === 'WHITE_DIVIDER_TOP'"></div>
    <div class="torn-paper-divider-4" v-if="props.type === 'WHITE_DIVIDER_BOTTOM'"></div>
</template>