import axios from "axios";

// Create axios request
const create = (headers = {}) => {
    return axios.create({
        baseURL: process.env.VUE_APP_BASE_API_URL,
        timeout: 25000,
        headers: headers,
        responseType: 'json', // default
    });
}

// Send a GET request
const get = (uri, params = {}, headers = {}) => {
    let queryString = params && Object.keys(params).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    }).join('&');
    return create(headers).get(uri + (queryString && '?' + queryString))
}

// Send a POST request
const post = (uri, params = {}, headers = {}) => {
    return create(headers).post(uri, params);
}

export {
    get,
    post
}