<script setup>
    import {onBeforeMount, defineProps, onMounted} from "vue";
    import Hero from "../components/modules/Hero.vue";
    import Welcome from "../components/modules/Welcome.vue";
    import Mission from "../components/modules/Mission.vue";
    import Testimonial from "../components/modules/Testimonial.vue";
    import TornPaperDivider from "../components/elements/TornPaperDivider.vue";
    import TaglineDivider from "../components/elements/TaglineDivider.vue";

    const props = defineProps({
        modules: {
            type: Object
        },
    })
    onBeforeMount(() => {

    });
    onMounted(() => {

    });
</script>

<template>
    <div class="module-container-hero">
        <Hero
            :sections="modules[0].moduleSections"
        />
    </div>
    <div class="module-container module-container-divider">
        <div class="row">
            <TornPaperDivider type="BLUE_DIVIDER_TOP" />
        </div>
    </div>

    <!-- the "find your inner strength" blurb at the top of the home page -->
    <Welcome />

    <TaglineDivider :repeats=10 />
    <!-- <img src="https://res.cloudinary.com/ilovekickboxing/image/upload/v1727889586/Gloves_On_World_Off-01_bvll3n.png" style="max-width: 300px; margin: 6rem auto" /> -->

    <Mission />
 
    <TornPaperDivider type="WHITE_DIVIDER_TOP" />
    <div 
        class="module-container module-container-testimonial"
        :style="{
            backgroundColor: 'rgb(251, 251, 252)',
            padding: '4rem 0 0 0',
            margin: '0',
            color: 'rgb(0, 0, 0)'
        }"
    >
        <div
            class="container">
            <div class="row">
                <Testimonial />
            </div>
        </div>
    </div>
    <div 
        class="module-container text-color-default module-container-watermark" 
        :style="{
            backgroundColor: 'rgb(251, 251, 252)', 
            padding: '0px', 
            color: 'rgb(0, 0, 0)'
        }"
    >
        <div class="container">
            <div class="row">
                <section class="section-container col-100">
                    <div class="element-contaier">
                        <h2 class="title_watermark element-text">Member Love </h2>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>