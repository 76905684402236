const triggerTrackEvents = (event_page, event_action, event_name, event_type = null)=> {    
  g4TrackEvent(event_page, event_action, event_name)
  fbTrackEvent(event_page, event_action, event_name, event_type)
}


const g4TrackEvent = (event_page, event_action, event_name) => { 
    console.log('triggerTrackEvents g4TrackEvent', event_page, event_action, event_name);  
    if(typeof gtag === "function") {
        gtag('event', event_name, {'page': event_page,'event_action': event_action,'event_name': event_name,});
    }
}

const create_UUID = ()=> {
    var dt = new Date().getTime();
    var uuid = 'xxxx-xxxx-4xxx-yxxx-xxxx-yxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return Date.now() + '.' + uuid;
}

let fbPixelUniqueId = create_UUID();
let fbPixelCounter = {};

const fbTrackEvent = (event_page, event_action, event_name, event_type) => {
    console.log('triggerTrackEvents fbTrackEvent', event_page, event_action, event_name);  
    const type = event_type ? event_type : 'trackCustom'
    const eventData = {
        'page': event_page,
        'event_action': event_action,
        'event_name': event_name,
    }

    if(fbPixelCounter[event_name] === undefined){
        fbPixelCounter[event_name] = 0;
    }
    fbPixelCounter[event_name]++;
    console.log(fbPixelCounter);
    if (typeof fbq !== 'undefined') {
        let eventId = 'ILKB.' + event_name + '.' + fbPixelUniqueId + '.' + fbPixelCounter[event_name];
        if (eventData) {
            fbq(type, event_name, eventData, {eventID: eventId });
        } else {
            fbq(type, event_name, null, {eventID: eventId });
        }
    }
}

export {
    triggerTrackEvents
}