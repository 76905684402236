<script setup>
    import {Splide, SplideSlide} from '@splidejs/vue-splide';
    import '@splidejs/splide/dist/css/themes/splide-default.min.css';
    import {ref, onMounted, onBeforeMount} from "vue";
    import {fetchFAQs} from "../../mixins/api";
    import Image from './Image.vue';
    import Text from './Text.vue';

    const classes = ref([]);

    const sliderOptions = {
        rewind: true,
        type: 'slide',
        perPage: 1,
        autoplay: true,
        pagination: true,
        arrows: false,
        padding: '0px',
        focus: 'center',
        autoWidth: false,
        direction: 'ttb',
        height   : '620px',
        classes: {
            pagination: 'splide__pagination splide__pagination_circle'
        },
        breakpoints: {
            992: {
                direction: false,
                height: 'auto',
            },
            640: {
                direction: false,
                height: 'auto',
                classes: {
                    pagination: 'splide__pagination splide__pagination_circle splide__pagination_mobile'
                },
            },
        }
    };
    const isMobile = ref(false);
    const checkIsMobile = () => {
        let width = window.innerWidth || 
        document.documentElement.clientWidth || 
        document.body.clientWidth;
        isMobile.value = width < 640 ? true : false;
    };

    const getFAQs = () => {
        fetchFAQs('workout').then((response) => {
            classes.value = response;
        });
    };
    onBeforeMount(() => {
    window.addEventListener("resize", checkIsMobile);
    });
    onMounted(() => {
        checkIsMobile();
        getFAQs();
    });

</script>

<template>
    <template v-if="isMobile">
        <Text type="H1" text="Class structure" class="mb-30 font-size-mobile-36" />
    </template>
    <Splide :options="sliderOptions">
        <SplideSlide v-for="(classDetails, index) in classes" :key="index">
            <div class="class-structure-box">
                <div class="class-structure-card">
                    <div class="class-structure-image">
                        <div class="img-box">
                            <Image
                                    :src="classDetails.image_link"
                                    :text="classDetails.name"
                            />
                        </div>
                    </div>
                    <div class="class-structure-content">
                        <template v-if="!isMobile">
                            <Text type="H1" text="Class structure" class="mb-30" />
                        </template>
                        <Text type="H2" :text="index+1" class="class-structure-number"/>
                        <Text type="H2" :text="'.' + classDetails.name" class="class-structure-title"/>
                        <Text type="P" :text="classDetails.description" class="class-structure-desc"/>
                    </div>
                </div>
            </div>
        </SplideSlide>
    </Splide>
</template>