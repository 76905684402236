<script setup>
    import Text from "@/components/elements/Text.vue";
    import {defineProps, ref} from "vue";
import Button from "./Button.vue";
import Modal from "./Modal.vue";
import CloseIcon from "../../assets/svg/CloseIcon.vue";
import Search from "./Search.vue";

    defineProps({
        currentStudio: {
            type: Object
        }
    });

    const isModalOption = ref(false);
    const showModalOption = () => {
        isModalOption.value = true;
    };
    const closeModalOption = () => {
        isModalOption.value = false;
    };
</script>
<template>
    <template v-if="currentStudio">
        <div class="studio-details-card" :class="mode-light">
            <div class="studio-details-left">
                <div class="studio-details-icon">
                    <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.1824 17.2895L19.4196 9.14211C20.4493 8.12368 21 6.77368 21 5.35263C21 3.93158 20.4253 2.58158 19.4196 1.56316C18.39 0.544737 17.0251 7.94075e-08 15.5884 7.94075e-08C14.1277 7.94075e-08 12.7868 0.568421 11.7571 1.56316L10.512 2.79474L9.26682 1.56316C7.15964 -0.521053 3.71152 -0.521053 1.58039 1.56316C-0.526796 3.64737 -0.526796 7.0579 1.58039 9.16579L10.512 18L11.1824 17.2895Z" fill="#EF3147"/>
                    </svg>
                </div>
                <div class="studio-details-left-title">
                    <Text type="H4" :text="currentStudio?.City + ', ' + currentStudio?.state?.Code"/>
                    <Button 
                        @click="showModalOption"
                        styled="link" 
                        text="Change studio" 
                    />
                </div>
            </div>
            <div class="studio-details-content">
                <Text :text="currentStudio?.Line1"/>
                <Text :text="currentStudio?.City + ', ' + currentStudio?.state?.Code + ' ' + currentStudio?.ZipCode"/>
                <Text :text="currentStudio?.phone"/>
            </div>
            <Modal modalLarge="true" v-if="isModalOption">
                <template #body>
                    <span class="modal-close" @click="closeModalOption">
                        <CloseIcon/>
                    </span>
                    <div class="studio-search-modal">
                        <Search @closeModalOptionOnSelect="closeModalOption"/>
                    </div>
                </template>
            </Modal>
        </div>
    </template>
</template>