<template>
    <div id="our_mission" class="module-container module-container-our_mission">
        <div class="container">
            <div class="row">
                <section class="section-container col-40-lg">
                    <div class="element-contaier">
                        <img src="https://res.cloudinary.com/ilovekickboxing/image/upload/v1727889577/ILKB_-_Main_Image_2_vq9zpd.png" alt="Man smiling while leaning against bag" class="img-fluid">
                    </div>
                </section>
                <section class="section-container col-60-lg">
                    <div class="element-contaier">
                        <h1 class="element-text text-align-left font-size-88 font-size-mobile-60 text-color-yellow">Our Mission</h1>
                        <br/>
                    </div>                   
                    <div class="element-contaier">
                        <h2 class="element-text text-align-left font-size-88 font-size-mobile-60">Changing Lives Through Kickboxing</h2>
                    </div>
                    <div class="element-contaier">
                        <!-- Modified paragraph element to include role="heading" for visually appearing titles -->
                        <p class="element-text text-align-left font-size-20">
                            <span>
                                At I Love Kickboxing, we strive to transform lives through the power of kickboxing fitness. We foster a supportive community that empowers people to achieve their personal best. Our studios are dedicated to creating an environment where every member feels valued and motivated, championing their fitness journey while building lasting connections along the way.
                            </span>
                        </p>
                    </div>
                    <div class="element-contaier">
                        <a href="/day-one" class="">
                            <button type="button" class="btn btn-primary"> Learn More</button>
                        </a>
                    </div>
                </section>

            </div>
        </div>
    </div>
</template>