<script setup>
import { onBeforeMount, defineProps, ref, onMounted } from "vue";

const props = defineProps({
  padding: String,
  margin: String,
  class: String,
})
const isMobile = ref(false);
const checkIsMobile = () => {
    let width = window.innerWidth || 
    document.documentElement.clientWidth || 
    document.body.clientWidth;
    isMobile.value = width < 640 ? true : false;
};

onBeforeMount(() => {
  window.addEventListener("resize", checkIsMobile);
});
onMounted(() => {
  checkIsMobile();
});
</script>
<template>
  <div 
    class="element-contaier"
    :class="[props.class, isMobile ? 'element-contaier-mobile' : '']"
    :style="{
      padding: props.padding, 
      margin: props.margin,
    }"
  >
    <slot></slot>
  </div>
</template>
