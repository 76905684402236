<script setup>
    import {Splide, SplideSlide} from '@splidejs/vue-splide';
    import '@splidejs/splide/dist/css/themes/splide-default.min.css';
    import {ref, onMounted, defineProps, computed} from "vue";
    import {fetchBios} from "../../mixins/api";
    import Image from './Image.vue';
    import Text from './Text.vue';

    const props = defineProps({
        currentStudio: {
            type: Object
        }
    });
    const instructors = ref([]);

    // Current Studio Id
    const studioId = computed(() => {
        return props?.currentStudio?.ID
            ? props?.currentStudio?.ID
            : 0;
    });

    const sliderOptions = {
        arrowPath: 'M40.9782 16.6354L25.2042 0.861441C24.8137 0.470916 24.1805 0.470916 23.79 0.861441C23.3995 1.25197 23.3995 1.88513 23.79 2.27565L37.8574 16.343L2.41398 16.343C1.8617 16.343 1.41398 16.7907 1.41398 17.343C1.41398 17.8953 1.8617 18.343 2.41398 18.343H37.8564L23.79 32.4094C23.3995 32.8 23.3995 33.4331 23.79 33.8236C24.1805 34.2142 24.8137 34.2142 25.2042 33.8236L40.9525 18.0753C41.1488 17.8927 41.2716 17.6322 41.2716 17.343C41.2716 17.0668 41.1596 16.8168 40.9786 16.6358C40.9784 16.6357 40.9783 16.6356 40.9782 16.6354Z',
      rewind: true,
      type: 'slide',
        perPage: 3,
        perMove   : 1,
        autoplay: true,
        pagination: false,
        arrows: true,
        padding: '0',
        focus  : 'center',
        classes: {
            arrows: 'splide__arrows splide-circle-arrows',
            arrow : 'splide__arrow splide-circle-arrow',
            prev  : 'splide__arrow--prev splide-circle-arrow-prev',
            next  : 'splide__arrow--next splide-circle-arrow-next',
        },
        breakpoints: {
            1024: {
              perPage: 3,
             
            },
            767: {
              perPage: 2,
          
            },
            640: {
                perPage: 1,
                arrows: false,
        
            },
        },
    };

    const getInstructors = () => {
        fetchBios('bio', studioId.value).then((response) => {
            instructors.value = response;
        });
    };
    onMounted(() => {
        getInstructors();
    });

</script>

<template>
    <div class="instructors-slider">
        <Splide :options="sliderOptions">
            <SplideSlide v-for="(instructor, index) in instructors" :key="index">
                <div class="instructor-box">
                    <div class="instructor-card">
                        <div class="img-box">
                            <Image
                                    :src="instructor.image_link"
                                    :text="instructor.name"
                            />
                        </div>
                        <div class="instructor-content socialmedia">
                          <div class="instructor-content-top">
                            <Text type="H4" :text="instructor.name" class="instructor-title"/>
                            <Text :text="instructor.description" class="instructor-desc"/>
                          </div>
                          <div class="instructor-content-bottom">
                            <div class="instructor-sl-main">
                                <a
                                    :href="instructor.social_link"
                                    class="social-icon instructor-sl"
                                    target="_blank"
                                >
                                  <template v-if="instructor.social_type === 'facebook'">
                                    <svg width="10" height="18" viewBox="0 0 10 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg" role="presentation">
                                      <path d="M9.34473 10.1247L9.86346 6.86724H6.62096V4.75337C6.62096 3.86237 7.07375 2.99331 8.52608 2.99331H10V0.220182C10 0.220182 8.66262 0.000244141 7.38359 0.000244141C4.7135 0.000244141 2.96826 1.56062 2.96826 4.38493V6.86781H0V10.1252H2.96826V18.0002H6.62096V10.1252L9.34473 10.1247Z"/>
                                    </svg>
                                  </template>
                                  <template v-else-if="instructor.social_type === 'instagram'">
                                    <svg width="19" height="18" viewBox="0 0 19 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg" role="presentation">
                                      <path d="M9.49745 5.99897C7.75305 5.99897 6.32949 7.34763 6.32949 9.00024C6.32949 10.6529 7.75305 12.0015 9.49745 12.0015C11.2418 12.0015 12.6654 10.6529 12.6654 9.00024C12.6654 7.34763 11.2418 5.99897 9.49745 5.99897ZM18.9989 9.00024C18.9989 7.7574 19.0108 6.52582 18.9371 5.28523C18.8635 3.84426 18.5165 2.5654 17.4043 1.51168C16.2897 0.455721 14.9421 0.129251 13.4211 0.0594534C12.1093 -0.0103438 10.8093 0.000913842 9.49982 0.000913842C8.18796 0.000913842 6.88798 -0.0103438 5.5785 0.0594534C4.0575 0.129251 2.70761 0.457973 1.59538 1.51168C0.480772 2.56765 0.136171 3.84426 0.0624975 5.28523C-0.0111759 6.52807 0.000706894 7.75966 0.000706894 9.00024C0.000706894 10.2408 -0.0111759 11.4747 0.0624975 12.7153C0.136171 14.1562 0.483149 15.4351 1.59538 16.4888C2.70999 17.5448 4.0575 17.8712 5.5785 17.941C6.89036 18.0108 8.19034 17.9996 9.49982 17.9996C10.8117 17.9996 12.1117 18.0108 13.4211 17.941C14.9421 17.8712 16.292 17.5425 17.4043 16.4888C18.5189 15.4328 18.8635 14.1562 18.9371 12.7153C19.0132 11.4747 18.9989 10.2431 18.9989 9.00024ZM9.49745 13.6181C6.80005 13.6181 4.62312 11.5557 4.62312 9.00024C4.62312 6.44477 6.80005 4.38237 9.49745 4.38237C12.1948 4.38237 14.3718 6.44477 14.3718 9.00024C14.3718 11.5557 12.1948 13.6181 9.49745 13.6181ZM14.5714 5.27172C13.9416 5.27172 13.433 4.7899 13.433 4.19325C13.433 3.59659 13.9416 3.11477 14.5714 3.11477C15.2012 3.11477 15.7098 3.59659 15.7098 4.19325C15.71 4.33492 15.6806 4.47524 15.6235 4.60617C15.5664 4.7371 15.4825 4.85606 15.3768 4.95624C15.271 5.05642 15.1455 5.13586 15.0073 5.18999C14.8691 5.24413 14.721 5.2719 14.5714 5.27172Z"/>
                                    </svg>
                                  </template>
                                  <template v-else-if="instructor.social_type === 'linkedin'">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" role="presentation">
                                      <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                                    </svg>
                                  </template>
                                  <template v-else-if="instructor.social_type === 'tiktok'">
                                    <svg width="14" height="18" viewBox="0 0 448 512" fill="currentColor" xmlns="http://www.w3.org/2000/svg" role="presentation">
                                      <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/>
                                    </svg>
                                  </template>
                                </a>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </SplideSlide>
        </Splide>
    </div>
</template>