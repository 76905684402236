<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  text: String,
  src: String,
  alt: String,
  imageStyled: String,
})
</script>

<template>
  <template v-if="props.imageStyled === 'TRUE'">
    <div class="image-styled">
      <div class="img-box">
        <img class="img-fluid" :src="props.src"
          :alt="props.text ? props.text : 'Background image for Day One menu screen'" />
      </div>
    </div>
  </template>

  <template v-else>
    <img :src="props.src" :alt="props.text ? props.text : 'Background image for Day One menu screen'"
      class="img-fluid" />
  </template>

</template>