
<template>
    <div class="carousel-container">
      <Splide :options="sliderOptions">
        <SplideSlide v-for="(image, index) in props.images" :key="index">
          <template v-if="image.videolink === 'TRUE'">
            <VideoLink 
              :src="image.video_source"
              :text="image.text"
              :width="image.width"
              :height="image.height"
            />
          </template>
          <template v-else-if="image.videoPlayer === 'TRUE'">
            <VideoPlayer
              :src="image.video_source"
              :width="image.width"
              :height="image.height"
            />
          </template>
          <template v-else>
            <div class="img-box">
              <Image 
                :src="image.image_source"
                :text="image.image_alt"
              />
            </div>
          </template>
        </SplideSlide>
      </Splide>
    </div>
  </template>
  
  <script setup>
  import { Splide, SplideSlide } from '@splidejs/vue-splide';
  import '@splidejs/splide/dist/css/themes/splide-default.min.css';
  import { defineProps } from 'vue';
  import Image from './Image.vue';
  import VideoLink from './VideoLink.vue';
  import VideoPlayer from './VideoPlayer.vue';
  
  const props = defineProps({
    images: Array,
  });
  
  const sliderOptions = {
    rewind: true,
    type: 'slide',
    perPage: 1,
    autoplay: true,
    pagination: true,
    arrows: false,
    padding: '0px',
    focus: 'center',
    autoWidth: false,
  };
  </script>
  
  <style scoped>
  /* Reset margin, padding, and border for body and other parent elements */
  body,
  html,
  .carousel-container,
  .splide__slide {
    margin: 0;
    padding: 0;
    border: 0;
  }
  
  /* Ensure the container takes the full width of the viewport */
  .carousel-container {
    width: 100%;
    overflow: hidden;
    position: relative; /* Ensure relative positioning for slides */
  }
/* Set max-height for the slide content */
.carousel-container .splide__slide {
  max-height: 100vh; /* Set maximum height to the viewport height */
  display: flex; /* Ensure slide content stretches horizontally */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  width: 100%; /* Set the width to 100% */
}
.carousel-container img {
    height: 600px;
}
  </style>