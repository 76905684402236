<script setup>
    import {Splide, SplideSlide} from '@splidejs/vue-splide';
    import '@splidejs/splide/dist/css/themes/splide-default.min.css';
    import {ref, onMounted} from "vue";
    import {fetchFAQs} from "../../mixins/api";
    import Image from './Image.vue';
    import Text from './Text.vue';
    import VideoLink from './VideoLink.vue';
    import Modal from './Modal.vue';
    import CloseIcon from '../../assets/svg/CloseIcon.vue';

    const punches = ref([]);

    const sliderOptions = {
        rewind: true,
        type: 'slide',
        perPage: 3,
        autoplay: true,
        pagination: false,
        arrows: true,
        padding: '0px',
        focus: 'center',
        autoWidth: false,
        classes: {
            arrows: 'splide__arrows splide-circle-arrows',
            arrow : 'splide__arrow splide-circle-arrow',
            prev  : 'splide__arrow--prev splide-circle-arrow-prev sl-prev-dark',
            next  : 'splide__arrow--next splide-circle-arrow-next sl-next-dark',
        },
        breakpoints: {
            640: {
                perPage: 1,
                pagination: false,
            },
            768: {
                perPage: 2,
                pagination: false,
            },
        },
    };

    const getFAQs = () => {
        fetchFAQs('exercise').then((response) => {
            punches.value = response;
        });
    };
    onMounted(() => {
        getFAQs();
    });
    const isModalVisible = ref(false);
    const closeModal = () => {
        isModalVisible.value = false;
    };

</script>

<template>
    <div class="punches-slider">
        <Splide :options="sliderOptions">
            <SplideSlide v-for="(punch, index) in punches" :key="index">
                <div class="punch-box">
                    <div class="punch-card">
                        <div class="punch-media">
                            <div class="img-box">
                                <Image
                                    :src="punch.image_link"
                                    :text="punch.name"
                                />
                            </div>
                            <button 
                                type="button" 
                                @click='isModalVisible = isModalVisible === index ? null : index' 
                                class="btn btn-dark btn-play"
                                aria-label="Play video"
                            >
                                <svg width="21" height="30" viewBox="0 0 21 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.1955 14.6945L0.328125 0.867432V29.2494L20.1955 14.6945Z" fill="currentColor"/></svg>
                            </button>
                        </div>
                        <div class="punch-content">
                            <Text type="H3" :text="punch.name" />
                            <Text type="P" :text="punch.description" />
                        </div>
                    </div>                    
                </div>
            </SplideSlide>
        </Splide>
        <template v-for="(punch, index) in punches" :key="index">
            <Modal v-if="index === isModalVisible">
                <template #body>
                    <span class="modal-close" @click="closeModal">
                        <CloseIcon />
                    </span>
                    <div class="punch-modal-video-box">
                        <VideoLink
                            :src="punch.video_link"
                            :text="punch.name"
                        />
                    </div>
                </template>
            </Modal>
        </template>
    </div>
</template>