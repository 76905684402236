import { createApp } from "vue";
import { createMetaManager, defaultConfig } from 'vue-meta';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/scss/app.scss";
import LoadScript from "vue-plugin-load-script";
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

const app = createApp(App);

const toastOptions = {
  position: "top-right",
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false
};

const defaultLang = 'en';
document.documentElement.lang = defaultLang;

const script = document.createElement('script');
script.async = true;
script.src = "https://www.googletagmanager.com/gtag/js?id=AW-16596255233";
document.head.appendChild(script);

script.onload = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'AW-16596255233');
};

app.config.globalProperties.$filters = {
  activePage: 'ourmission',
  goto(elementId) {
    for (const item of document.querySelectorAll(".nav-link.active")) {
      item.classList.remove("active");
    }
    let currentNav = document.getElementById(elementId + "-link");
    currentNav.classList.add("active");
    document.getElementById(elementId).scrollIntoView({
      behavior: "smooth",
    });
  },
};

app
  .use(store)
  .use(router)
  .use(createMetaManager(false, {
    ...defaultConfig,
    meta: { tag: 'meta', nameless: true },
  }))
  .use(LoadScript)
  .use(Toast, toastOptions)
  .mount("#app");

// Note: Ensure your server is configured to serve static files like robots.txt and sitemap.xml.


// Serve static files like robots.txt and sitemap.xml from the appropriate directory
// Note: This code won't work in the frontend part of a Vue.js application.
// You should configure your server (e.g., Express) to serve these files from the appropriate directory.

// app.use('/robots.txt', express.static(path.join(__dirname, 'dist/static/robots.txt')));
// app.use('/sitemap.xml', express.static(path.join(__dirname, 'dist/static/sitemap.xml')));
