<script setup>
import { defineProps, ref, onMounted, onBeforeMount, onUpdated, watch, computed } from "vue";
import { useMeta } from "vue-meta";
// @ is an alias to /src
import LayoutDefault from "../components/layout/LayoutDefault.vue";
import ModuleContainer from "../components/containers/ModuleContainer.vue"
import Testimonial from "../components/modules/Testimonial.vue";
import StudioTestimonial from "../components/modules/StudioTestimonial.vue";
import StudioService from "../components/modules/StudioService.vue";
import StudioContract from "../components/modules/StudioContract.vue";
import FindLocation from "../components/modules/FindLocation.vue"

import { post } from "../mixins/http_handler";
import { getLocationsById, getModuleByModuleId, getModulesByPageName } from "../mixins/api";
import CustomModule from "../components/modules/CustomModule.vue";
import Hero from "../components/modules/Hero.vue";
import { useRoute } from 'vue-router'
import TornPaperDivider from "../components/elements/TornPaperDivider.vue";
import LineDivider from "../components/elements/LineDivider.vue";
import ILKBHome from "./ILKBHome.vue";
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitization

document.title = 'I Love Kickboxing'

const route = useRoute()
const queryParams = route.query;

const props = defineProps({
    pageName: {
        type: String,
        required: true
    },
    pageKey: {
        type: String,
        required: true
    },
    isPreview: {
        type: Boolean,
        default: false
    }
});

const metaTags = ref([]);
const metaScripts = ref([]);
const metaNoScripts = ref([]);
const metaLinks = ref([]);
const currentStudio = ref(null);
const title = ref('I Love Kickboxing');

const isMobile = ref(false);
const isDesktop = ref(false);

const replaceMetaData = (tracking_value) => {
    if (route?.params?.studio) {
        title.value = 'Kickboxing Classes Near ' + currentStudio.value.City + ' | iLoveKickboxing';
        tracking_value = tracking_value.replaceAll('##STUDIO-CITY##', currentStudio.value.City)
            .replaceAll('##STUDIO-URL-SLUG##', currentStudio.value.url_slug);
    }
    return tracking_value;
};

const getMetaInfo = async () => {
    let href = process.env.VUE_APP_META_TAG_URL;
    await post("/", {
        query:
            `{metaTags(url:"` +
            href +
            `")
        {website_seo_tracking_tags_id
            page_name
            page_section
            tracking_type
            tracking_comment
            tracking_sequence
            tracking_value
          }}`
    })
        .then((response) => {
            console.log('metaTags ', response.data.data.metaTags);
            const metaTagsApi = response.data.data.metaTags;
            /* Metas */
            metaTagsApi
                .filter(function (metaTag) {
                    return (
                        metaTag.tracking_type == "meta" &&
                        (metaTag.page_name == "all" || metaTag.page_name == props.pageKey)
                    );
                })
                .forEach(metaTag => {
                    metaTags.value.push(...JSON.parse(replaceMetaData(metaTag.tracking_value)));
                });

            /* Scripts */
            metaTagsApi
                .filter(function (metaTag) {
                    return (
                        metaTag.tracking_type == "script" &&
                        (metaTag.page_name == "all" || metaTag.page_name == props.pageKey)
                    );
                })
                .forEach(metaTag => {
                    metaScripts.value.push(...JSON.parse(replaceMetaData(metaTag.tracking_value)));
                });
            /* No Scripts */
            metaTagsApi
                .filter(function (metaTag) {
                    return (
                        metaTag.tracking_type == "noscript" &&
                        (metaTag.page_name == "all" || metaTag.page_name == props.pageKey)
                    );
                })
                .forEach(metaTag => {
                    metaNoScripts.value.push(...JSON.parse(replaceMetaData(metaTag.tracking_value)));
                });
            /* Links */
            metaTagsApi
                .filter(function (metaTag) {
                    return (
                        metaTag.tracking_type == "link" &&
                        (metaTag.page_name == "all" || metaTag.page_name == props.pageKey)
                    );
                })
                .forEach(metaTag => {
                    metaLinks.value.push(...JSON.parse(replaceMetaData(metaTag.tracking_value)));
                });
        })
        .catch((err) => {
            console.log(err);
        });
};
const checkIsMobile = () => {
    let width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
    isMobile.value = width < 640 ? true : false;
    isDesktop.value = width > 641 ? true : false;
};


const modules = ref([]);

const getModules = async () => {
    console.log('useRoute pageName', props.pageName);
    if (props.isPreview) {
        console.log('useRoute moduleId', route?.params?.moduleId)
        await getModuleByModuleId(route?.params?.moduleId).then((response) => {
            modules.value = response;
        });
    } else {
        await getModulesByPageName(props.pageName).then((response) => {
            modules.value = response;
        });
    }
};

const setCurrentStudio = () => {
    sessionStorage.setItem('cookieCurrentStudio', JSON.stringify({
        slug: currentStudio.value?.url_slug,
        name: currentStudio.value?.City
    }));
};
const Mainsiteurl = process.env.BASE_URL;
const getCurrentStudio = async () => {
    if (route?.params?.studio) {
        await getLocationsById(route?.params?.studio).then((response) => {
            response.schools_details = JSON.parse(response.schools_details);
            if (response.IsActive == false) {
                //  alert("Studio is closed. " + Mainsiteurl);
                window.location.href = Mainsiteurl;
                return;
            }
            currentStudio.value = response;
            setCurrentStudio();
        })
            .then(() => getMetaInfo())
            .then(() => getModules())
            .then(() => setMeta())
            .then(() => loadStudioSpecificScripts());
    } else {
        await getMetaInfo();
        await getModules();
        setMeta();
    }
}
const setMeta = () => {

}

const loadStudioSpecificScripts = async () => {
    if (currentStudio.value?.ID) {
        try {
            const siteId = currentStudio.value.ID;
            const scriptsData = await fetchSeoTrackingData(siteId);
            processScriptsData(scriptsData, siteId);
        } catch (err) {
            console.error('Error loading studio-specific scripts:', err);
        }
    }
};

// Fetch SEO tracking data from API
const fetchSeoTrackingData = async (siteId) => {
    const response = await post("/", {
        query: `{getsiteseotracking(input:{site_id: ${siteId}}) {
            id
            site_id
            context
            tracking_scripts
            google_my_business_url
        }}`
    });
    console.log("API Response:", response.data);
    return response.data.data.getsiteseotracking;
};

// Process each script data item
const processScriptsData = (scriptsData, siteId) => {
    if (scriptsData && scriptsData.length > 0) {
        scriptsData.forEach(scriptData => {
            if (scriptData.context === 'mini_site' && scriptData.tracking_scripts) {
                handleScriptTags(scriptData.tracking_scripts, siteId);
            }
        });
    } else {
        console.log(`No SEO scripts found for site_id ${siteId}`);
    }
};

// Handle individual script tags, either external or inline
const handleScriptTags = (trackingScripts, siteId) => {
    const scriptMatches = trackingScripts.match(/<script\b[^>]*>([\s\S]*?)<\/script>/gi);

    if (scriptMatches) {
        scriptMatches.forEach(scriptTag => {
            const srcMatch = scriptTag.match(/src=["']([^"']+)["']/);
            const asyncMatch = /async/.test(scriptTag);

            if (srcMatch) {
                appendExternalScript(srcMatch[1], asyncMatch);
            } else {
                appendInlineScript(scriptTag);
            }
        });
    } else {
        console.log("No valid script tags found in tracking_scripts for site_id", siteId);
    }
};

// Append external script with async if applicable
const appendExternalScript = (src, isAsync) => {
    const newScript = document.createElement('script');
    newScript.src = src;
    newScript.async = isAsync;
    document.body.appendChild(newScript);
};

// Append sanitized inline script
const appendInlineScript = (scriptTag) => {
    const inlineContent = scriptTag.replace(/<\/?script[^>]*>/g, '');
    const sanitizedContent = DOMPurify.sanitize(inlineContent);

    const newScript = document.createElement('script');
    newScript.type = 'text/javascript';
    newScript.innerHTML = sanitizedContent;
    document.body.appendChild(newScript);
};



onBeforeMount(async () => {
    window.addEventListener("resize", checkIsMobile);
    await getCurrentStudio();
});
onMounted(async () => {
    checkIsMobile();
});

useMeta(
    computed(() => ({
        meta: metaTags.value,
        script: metaScripts.value,
        noscript: metaNoScripts.value,
        link: metaLinks.value
    }))
)

watch(title, async (newTitle) => {
    if (newTitle) {
        document.title = newTitle
    }
})

</script>

<template>
    <metainfo>
        <template v-slot:title="{ content }">{{ content }}</template>
    </metainfo>
    <LayoutDefault :currentStudio="currentStudio" :queryParams="queryParams">
        <template v-if="modules && modules.length > 0">
            <!-- new home page template -->
            <template v-if="(pageName === 'HOME_PAGE') && modules && modules.length > 0">
                <ILKBHome :modules="modules" />
            </template>
            <!-- end home page template -->
            <template v-else>
                <template v-for="(module, index) in modules" :key="index">
                    <template v-if="module.module_type === 'HERO_BANNER'">
                        <template v-if="module.module_name === 'HERO_BANNER'">
                            <ModuleContainer class="module-container-hero"
                                :backgroundColor="module.module_background_color" :textColor="module.module_text_color"
                                :padding="module.module_padding" :margin="module.module_margin"
                                :containerFluid="module.is_module_content_full_width ? true : false">
                                <Hero :sections="module.moduleSections" :currentStudio="currentStudio" />
                            </ModuleContainer>
                        </template>
                    </template>

                    <template
                        v-else-if="(module.module_type === 'CUSTOM') || (module.module_type === 'STUDIO_REFERRIZER_WIDGET' && currentStudio?.referrizer_widget)">
                        <ModuleContainer :backgroundColor="module.module_background_color"
                            :textColor="module.module_text_color" :padding="module.module_padding"
                            :margin="module.module_margin"
                            :containerFluid="module.is_module_content_full_width ? true : false"
                            :class="'module-container-' + module.module_name.toLowerCase().trim()"
                            :id="module.module_name.toLowerCase().trim()">
                            <CustomModule :sections="module.moduleSections" :currentStudio="currentStudio" />
                        </ModuleContainer>
                    </template>
                    <template v-else-if="module.module_type === 'GOOGLE_REVIEWS'">
                        <ModuleContainer class="module-container-testimonial"
                            :backgroundColor="module.module_background_color" :textColor="module.module_text_color"
                            :padding="module.module_padding" :margin="module.module_margin"
                            :containerFluid="module.is_module_content_full_width ? true : false">
                            <Testimonial />
                        </ModuleContainer>
                    </template>
                    <template v-else-if="module.module_type === 'STUDIO_GOOGLE_REVIEWS'">
                        <ModuleContainer class="module-container-studio-testimonial"
                            :backgroundColor="module.module_background_color" :textColor="module.module_text_color"
                            :padding="module.module_padding" :margin="module.module_margin"
                            :containerFluid="module.is_module_content_full_width ? true : false">
                            <StudioTestimonial :currentStudio="currentStudio" />
                        </ModuleContainer>
                    </template>
                    <template v-else-if="module.module_type === 'STUDIO_SERVICES'">
                        <template v-if="currentStudio?.mbServices && currentStudio.mbServices.length > 0">
                            <ModuleContainer padding="0 0 0 0" containerFluid="false"
                                class="module-container-line-divider">
                                <LineDivider />
                            </ModuleContainer>
                            <ModuleContainer class="module-container-services"
                                :backgroundColor="module.module_background_color" :textColor="module.module_text_color"
                                :padding="module.module_padding" :margin="module.module_margin"
                                :containerFluid="module.is_module_content_full_width ? true : false"
                                :id="module.module_name.toLowerCase().trim()">
                                <StudioService :currentStudio="currentStudio" />
                            </ModuleContainer>
                        </template>
                    </template>

                    <template v-else-if="module.module_type === 'STUDIO_CONTRACTS'">
                        <template v-if="currentStudio?.mbContracts && currentStudio.mbContracts.length > 0">
                            <ModuleContainer padding="0 0 0 0" containerFluid="false"
                                class="module-container-line-divider">
                                <LineDivider />
                            </ModuleContainer>
                            <ModuleContainer class="module-container-services"
                                :backgroundColor="module.module_background_color" :textColor="module.module_text_color"
                                :padding="module.module_padding" :margin="module.module_margin"
                                :containerFluid="module.is_module_content_full_width ? true : false"
                                id="studio_contracts">
                                <StudioContract :currentStudio="currentStudio" />
                            </ModuleContainer>
                        </template>
                    </template>

                    <template v-else-if="module.module_type === 'TAGLINE_MODULE_DIVIDER'">
                        <template
                            v-if="(isDesktop && module.device === 'DESKTOP') || (isMobile && module.device === 'MOBILE') || (module.device === 'ALL')">
                            <ModuleContainer class="module-container-tagline"
                                :backgroundColor="module.module_background_color" :textColor="module.module_text_color"
                                :padding="module.module_padding" :margin="module.module_margin"
                                :containerFluid="module.is_module_content_full_width ? true : false">
                                <CustomModule :sections="module.moduleSections" />
                            </ModuleContainer>
                        </template>
                    </template>
                    <template v-else-if="module.module_type === 'WATERMARK_MODULE_DIVIDER'">
                        <template
                            v-if="(isDesktop && module.device === 'DESKTOP') || (isMobile && module.device === 'MOBILE') || (module.device === 'ALL')">
                            <ModuleContainer class="module-container-watermark"
                                :backgroundColor="module.module_background_color" :textColor="module.module_text_color"
                                :padding="module.module_padding" :margin="module.module_margin"
                                :containerFluid="module.is_module_content_full_width ? true : false">
                                <CustomModule :sections="module.moduleSections" />
                            </ModuleContainer>
                        </template>
                    </template>
                    <template v-else-if="module.module_type === 'FIND_LOCATION'">
                        <ModuleContainer class="module-container-find-location"
                            :backgroundColor="module.module_background_color" :textColor="module.module_text_color"
                            :padding="module.module_padding" :margin="module.module_margin"
                            :containerFluid="module.is_module_content_full_width ? true : false">
                            <FindLocation />
                        </ModuleContainer>
                    </template>
                    <template v-else-if="module.module_type === 'BLUE_TURN_PAPER_MODULE_DIVIDER_TOP'">
                        <template v-if="isDesktop && module.device === 'DESKTOP'">
                            <ModuleContainer padding="0 0 0 0" containerFluid="true" class="module-container-divider">
                                <TornPaperDivider type="BLUE_DIVIDER_TOP" />
                            </ModuleContainer>
                        </template>
                        <template v-else-if="isMobile && module.device === 'MOBILE'">
                            <ModuleContainer padding="0 0 0 0" containerFluid="true" class="module-container-divider">
                                <TornPaperDivider type="BLUE_DIVIDER_TOP" />
                            </ModuleContainer>
                        </template>
                        <template v-else-if="module.device === 'ALL'">
                            <ModuleContainer padding="0 0 0 0" containerFluid="true" class="module-container-divider">
                                <TornPaperDivider type="BLUE_DIVIDER_TOP" />
                            </ModuleContainer>
                        </template>
                    </template>
                    <template v-else-if="module.module_type === 'BLUE_TURN_PAPER_MODULE_DIVIDER_BOTTOM'">
                        <template v-if="isDesktop && module.device === 'DESKTOP'">
                            <ModuleContainer padding="0 0 0 0" containerFluid="true" class="module-container-divider">
                                <TornPaperDivider type="BLUE_DIVIDER_BOTTOM" />
                            </ModuleContainer>
                        </template>
                        <template v-else-if="isMobile && module.device === 'MOBILE'">
                            <ModuleContainer padding="0 0 0 0" containerFluid="true" class="module-container-divider">
                                <TornPaperDivider type="BLUE_DIVIDER_BOTTOM" />
                            </ModuleContainer>
                        </template>
                        <template v-else-if="module.device === 'ALL'">
                            <ModuleContainer padding="0 0 0 0" containerFluid="true" class="module-container-divider">
                                <TornPaperDivider type="BLUE_DIVIDER_BOTTOM" />
                            </ModuleContainer>
                        </template>
                    </template>
                    <template v-else-if="module.module_type === 'WHITE_TURN_PAPER_MODULE_DIVIDER_BOTTOM'">
                        <template v-if="isDesktop && module.device === 'DESKTOP'">
                            <ModuleContainer padding="0 0 0 0" containerFluid="true" class="module-container-divider">
                                <TornPaperDivider type="WHITE_DIVIDER_BOTTOM" />
                            </ModuleContainer>
                        </template>
                        <template v-else-if="isMobile && module.device === 'MOBILE'">
                            <ModuleContainer padding="0 0 0 0" containerFluid="true" class="module-container-divider">
                                <TornPaperDivider type="WHITE_DIVIDER_BOTTOM" />
                            </ModuleContainer>
                        </template>
                        <template v-else-if="module.device === 'ALL'">
                            <ModuleContainer padding="0 0 0 0" containerFluid="true" class="module-container-divider">
                                <TornPaperDivider type="WHITE_DIVIDER_BOTTOM" />
                            </ModuleContainer>
                        </template>
                    </template>
                    <template v-else-if="module.module_type === 'WHITE_TURN_PAPER_MODULE_DIVIDER_TOP'">
                        <template v-if="isDesktop && module.device === 'DESKTOP'">
                            <ModuleContainer padding="0 0 0 0" containerFluid="true" class="module-container-divider">
                                <TornPaperDivider type="WHITE_DIVIDER_TOP" />
                            </ModuleContainer>
                        </template>
                        <template v-else-if="isMobile && module.device === 'MOBILE'">
                            <ModuleContainer padding="0 0 0 0" containerFluid="true" class="module-container-divider">
                                <TornPaperDivider type="WHITE_DIVIDER_TOP" />
                            </ModuleContainer>
                        </template>
                        <template v-else-if="module.device === 'ALL'">
                            <ModuleContainer padding="0 0 0 0" containerFluid="true" class="module-container-divider">
                                <TornPaperDivider type="WHITE_DIVIDER_TOP" />
                            </ModuleContainer>
                        </template>
                    </template>
                    <template v-else-if="module.module_type === 'LINE_MODULE_DIVIDER'">
                        <ModuleContainer padding="0 0 0 0" containerFluid="false" class="module-container-line-divider">
                            <LineDivider />
                        </ModuleContainer>
                    </template>
                </template>
            </template>
        </template>
    </LayoutDefault>
</template>
