<template>
    <div id="welcome_to_ilkb" class="module-container module-container-welcome">
        <div class="container">
            <div class="row flex-row-reverse">
                <section class="section-container col-40-lg">
                    <div class="element-contaier">
                        <img 
                            src="https://res.cloudinary.com/ilovekickboxing/image/upload/v1727889581/ILKB_-_Main_Image_1_w93pm6.png" alt="Man smiling while leaning against bag" class="img-fluid">
                    </div>
                </section>
                <section class="section-container col-60-lg">
                    <div class="element-contaier">
                        <h2 class="element-text text-align-left font-size-88 font-size-mobile-60">Find Your Inner Strength </h2>
                    </div>
                    <div class="element-contaier">
                        <!-- Modified paragraph element to include role="heading" for visually appearing titles -->
                        <p class="element-text text-align-left font-size-20">
                            <span>
                                Discover the ultimate fitness studio where you can escape the everyday noise and focus entirely on yourself. At ILKB, we create a space for you to recharge, push your limits, and build unwavering strength and confidence.
                            </span>
                        </p>
                    </div>
                    <div class="element-contaier">
                        <!-- Modified paragraph element to include role="heading" for visually appearing titles -->
                        <p class="element-text text-align-left font-size-20">
                            <span>
                                Surrounded by a supportive community, you'll find encouragement and camaraderie at every turn. ILKB is more than just a workout—it’s a place where your goals are celebrated, your progress is championed, and every kick and punch bring you closer to your best self.
                            </span>
                        </p>
                    </div>
                    <div class="element-contaier">
                        <a href="/action-packed-workout" class="">
                            <button type="button" class="btn btn-primary"> Explore the Workout</button>
                        </a>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>