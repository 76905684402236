<script setup>
    import {ref, onMounted} from "vue";
    import Text from './Text.vue';
    import Button from './Button.vue';

    const isCookies = ref(false);

    const onAllowAllCookies = () => {
        sessionStorage.setItem('cookieConsentAccepted', true)
        isCookies.value = false;
    };
    onMounted(() => {
        const cookieConsent = sessionStorage.getItem('cookieConsentAccepted')
        setTimeout(function() {
            if(!cookieConsent) {
                isCookies.value = true;
            }
        }, 3000);        
    });
</script>

<template>
    <div class="cookies-wrp" v-if="isCookies">
        <div class="container">
            <div class="cookies-main">
                <div class="cookies-text">
                    <Text 
                        type="H5" 
                        text="This website uses cookies" 
                        style="font-size: 14px;"
                    />
                    <Text 
                        type="PARAGRAPH" 
                        text="Cookies are important to the proper functioning of a site. To improve your experience, we can use cookies to remember log-in details and provide secure log-in, collect statistics to optimize site functionality, and deliver content tailored to your interests. By clicking on 'Allow all cookies' or using the website, you agree to our use of cookies. You may change your consent by rejecting optional cookies in the settings or by blocking all or some cookies in your browser. If you choose to block all cookies (including essential cookies) you may not be able to access all or parts of the site." 
                         style="font-size: 14px;"
                    />
                </div>
                <div class="d-flex justify-content-end">
                    <Button
                        class="btn-sm"
                        text="Allow all cookies"
                        @click="onAllowAllCookies"
                    />
                </div>
            </div>
        </div>
    </div>
</template>