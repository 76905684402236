<script setup>
import { ref, onMounted, defineEmits } from "vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import Button from "./Button.vue";
import { getLocationsByZipCode, getNearByStudios } from "../../mixins/api";
import Text from "@/components/elements/Text.vue";
import { useRoute, useRouter } from "vue-router";
import { triggerTrackEvents } from "../../mixins/events_handler";

const route = useRoute();
const queryParams = route.query;
const router = useRouter();

const emit = defineEmits(['closeModalOptionOnSelect'])
const schema = Yup.object().shape({
  zipcode: Yup.string().required("State, City, Postal Code, or Zip Code is required"),
});
const customer = ref({
  zipcode: "",
});
const studios = ref([]);
const locations = ref(null); // Use null instead of an empty object
const isErrorMessageStudiosExists = ref(false);
const franchiseUrl = process.env.VUE_APP_FRANCHISE_URL;
const onSubmit = () => {
  triggerTrackEvents('Home', 'Find Studio', 'home_find_studio');
  getLocationsByZipCode(customer.value.zipcode, studioResults);
};
const studioResults = (response) => {
  studios.value = response;
  isErrorMessageStudiosExists.value = true;
}



const getLatLang = () => {
  return new Promise((resolve) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        let pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        const currentDate = new Date();
        // Use the environment variable for expiry time
        const expiryHours = process.env.VUE_APP_LOCATION_EXPIRY_HOURS;
        console.log(process.env.VUE_APP_LOCATION_EXPIRY_HOURS, "process.env.VUE_APP_LOCATION_EXPIRY_HOURS")
        const expiryDate = new Date(currentDate.getTime() + expiryHours * 60 * 60 * 1000);

        // Use the environment variable for expiry time in minutes
        // const expiryMinutes = process.env.VUE_APP_EXPIRY_TIME_MINUTES; // VUE_APP_EXPIRY_TIME_MINUTES=120 (2 hours)
        // const expiryDate = new Date(currentDate.getTime() + expiryMinutes * 60 * 1000);

        const currentLocation = {
          pos: pos,
          expiry: expiryDate
        };
        localStorage.setItem('ilkblocationData', JSON.stringify(currentLocation)); // Store location data as JSON string
        locations.value = pos;
        resolve();
      }, function () {
        console.log('Location cannot be found');
        resolve();
      })
    } else {
      resolve();
    }
  })
};

const getStudios = async () => {
  if (locations.value) {
    const studiosResponse = await getNearByStudios(locations.value.lat, locations.value.lng);
    return studiosResponse.slice(0, 5); // Limit to the first 5 locations
  }
  return []; // Return an empty array if locations are not available yet
};

const goToStudio = (studioSlug) => {
  emit('closeModalOptionOnSelect', true);
  router.push({ path: '/' + studioSlug + '/signup', query: queryParams })
    .then(() => { router.go(0) });
}

const goToStudioPage = (studioSlug) => {
  router.push({ path: '/' + studioSlug, query: queryParams })
    .then(() => { router.go(0) });
}
const getLocationFromStorage = () => {
  const storedLocation = localStorage.getItem('ilkblocationData');
  if (storedLocation) {
    try {
      const locationData = JSON.parse(storedLocation);
      const expiryTime = new Date(locationData.expiry);
      const currentTime = new Date();
      console.log(currentTime, "current time");
      console.log(expiryTime, "expiry time");
      if (currentTime <= expiryTime) {
        return locationData.pos;
      } else {
        localStorage.removeItem('ilkblocationData'); // Remove expired location data
      }
    } catch (error) {
      console.warn('Invalid location data in localStorage:', error);
      localStorage.removeItem('ilkblocationData'); // Remove invalid location data
    }
  }
  return null;
};


onMounted(() => {
  const storedLocation = getLocationFromStorage();

  if (storedLocation) {
    locations.value = storedLocation;
    getStudios().then(studioResults);
  } else {
    getLatLang().then(() => {
      if (locations.value && locations.value.lat && locations.value.lng) {
        getStudios().then(studioResults);
      }
    });
  }
});
</script>

<template>
  <div class="zipcode-form-main">
    <div class="zipcode_form">
      <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
        <div class="">
          <div class="hero_zipcode_field">
            <Field type="text" v-model="customer.zipcode" name="zipcode" id="zipcode" class="form-control"
              placeholder="Enter State, City, Postal Code, or Zip Code" :class="{ 'is-invalid': errors.zipcode }" />
            <div class="invalid-feedback" v-if="errors.zipcode">{{ errors.zipcode }}</div>
            <Button type="submit">
              <template #icon>
                Find studios
                <svg width="17" height="15" viewBox="0 0 17 15" fill="currentColor" xmlns="http://www.w3.org/2000/svg" role="presentation">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10.1395 0.852615C9.74896 0.46209 9.11579 0.46209 8.72527 0.852615C8.33474 1.24314 8.33474 1.8763 8.72527 2.26683L12.7452 6.28677L1.41479 6.28677C0.862509 6.28677 0.414794 6.73449 0.414794 7.28677C0.414794 7.83906 0.862509 8.28677 1.41479 8.28677H12.7446L8.72527 12.3061C8.33474 12.6966 8.33474 13.3298 8.72527 13.7203C9.11579 14.1108 9.74896 14.1108 10.1395 13.7203L15.8552 8.00458C15.8922 7.96864 15.9265 7.92985 15.9577 7.88857C16.0234 7.80154 16.0731 7.70621 16.1067 7.60665C16.118 7.57312 16.1276 7.5388 16.1353 7.50379C16.2068 7.18128 16.1171 6.83024 15.8662 6.57934L10.1395 0.852615Z" />
                </svg>
              </template>
            </Button>
          </div>
        </div>
      </Form>
    </div>
    <div class="search-dropdown-wrapper" v-if="studios.length > 0">
      <div class="search-dropdown-card" v-for="(studio) in studios" :key="studio.ID">
        <div class="search-dropdown-text">
          <Text type="H4" :text="studio.City + ', ' + studio.state.Code" />
          <Text :text="studio.Line1" />
        </div>
        <div class="search-dropdown-cta">
          <template v-if="$route.name == 'StudioSignup'">
            <Button @click="goToStudio(studio.url_slug)" text="Select" />
          </template>
          <template v-else>
            <Button @click="goToStudioPage(studio.url_slug)" text="Select" />
          </template>
        </div>
      </div>
    </div>
    <div v-else-if="isErrorMessageStudiosExists" class="mt-10 ml-10 mr-10 font-size-16">
      There are no active studios in your area. Interesting in bringing ILKB to your community?
      <a :href="franchiseUrl" target="_blank" class="dark">
        Checkout our business opportunities.
      </a>
    </div>
  </div>
</template>