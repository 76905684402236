<script setup>
    import {defineProps} from "vue";
    import HtmlComponent from "./HtmlComponent.vue";

    const props = defineProps({
        rawHtml: String,
        class: String
    })
</script>

<template>
    <div class="followusbrand">
        <HtmlComponent class="followusbrand-text"
                       :class="props.class"
                       :rawHtml="props.rawHtml"
                       v-for="n in 10"
                       :key="n"
        ></HtmlComponent>
    </div>
</template>