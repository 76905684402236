<script setup>
import { defineProps } from "vue";

const props = defineProps({
    class: String,
    small: Boolean,
})
</script>

<template>
    <div class="loader-wrapper" :class="props.small ? 'loader-small' : ''">
        <div class="loader-main">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <div class="loader-heart">
                <div></div>
            </div>
        </div>
    </div>
</template>