<script setup>

    import {onBeforeMount, defineProps, onMounted} from "vue";

    const props = defineProps({
        sections: {
            type: Object
        },
        class:{
            type: String,
        },
        containerFluid:{
            type: Boolean,
        },
        backgroundColor:{
            type: String,
        },
        padding:{
            type: String,
        },
        margin:{
            type: String,
        },
        textColor:{
            type: String,
        },
    })

    onBeforeMount(() => {

    });
    onMounted(() => {

    });
</script>
<template>
    <div 
        class="module-container" 
        :class="[props.textColor ? 'text-color-default' : '', props.class]" 
        :style="{
            backgroundColor: props.backgroundColor,
            padding: props.padding,
            margin: props.margin,
            color: props.textColor,
        }"
    >
        <div
            :class="[props.containerFluid ? 'container-fluid' : 'container']">
            <div class="row">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
