<script setup>
import { defineProps } from 'vue'
import FollowusBrand from './FollowusBrand.vue';
import HtmlComponent from "./HtmlComponent.vue";
import JudgementFree from "./JudgementFree.vue";

const props = defineProps({
    text: String,
    type: String,
    class: String
})
</script>

<template>
    <h1 :class="props.class" v-if="props.type === 'H1'">
        {{ props.text }}
        <slot name="H1"></slot>
    </h1>
    <h2 :class="props.class" v-else-if="props.type === 'H2'">
        {{ props.text }}
        <slot name="H2"></slot>
    </h2>
    <h3 :class="props.class" v-else-if="props.type === 'H3'">
        {{ props.text }}
        <slot name="H3"></slot>
    </h3>
    <h4 :class="props.class" v-else-if="props.type === 'H4'">
        {{ props.text }}
        <slot name="H4"></slot>
    </h4>
    <h5 :class="props.class" v-else-if="props.type === 'H5'">
        {{ props.text }}
        <slot name="H5"></slot>
    </h5>
    <h6 :class="props.class" v-else-if="props.type === 'H6'">
        {{ props.text }}
        <slot name="H6"></slot>
    </h6>
    <span :class="props.class" v-else-if="props.type === 'SPAN'">
        {{ props.text }}
    </span>
    <span class="flexable" :class="props.class" v-else-if="props.type === 'SPANBUTTON'">
        {{ props.text }}
    </span>
    <template v-else-if="props.type === 'HTML'">
        <HtmlComponent :class="props.class" :rawHtml="props.text" />
    </template>
    <template v-else-if="props.type === 'TAGLINE'">
        <JudgementFree :class="props.class" :rawHtml="props.text" />
    </template>
    <template v-else-if="props.type === 'TAGLINEFOLLOWUS'">
        <FollowusBrand :class="props.class" :rawHtml="props.text" />
    </template>
    <h2 class="title_watermark" :class="props.class" v-else-if="props.type === 'WATERMARK'">
        {{ props.text }}
        <slot name="H2"></slot>
    </h2>
    <!-- Modified paragraph element to include role="heading" for visually appearing titles -->
    <p :class="props.class" v-else>
        <span v-if="props.type === 'TITLE'" :role="props.type === 'TITLE' ? 'heading' : null" aria-level="2">
            {{ props.text }}
        </span>
        <span v-else>
            {{ props.text }}
        </span>
    </p>
</template>