<script setup>
    import CustomSection from "./CustomSection.vue";

    import {defineProps, computed, reactive} from "vue";

    const props = defineProps({
        sections: {
            type: Object
        },
        currentStudio: {
            type: Object
        },
    IsheroBaner: {
        type: Object
    },
    });

    // Section sorting
    const sections = computed(() => {
        return props.sections.length > 0
            ? reactive(props.sections).sort((a, b) => a.section_order - b.section_order)
            : [];
    });

</script>
<template>
    <template v-for="(section, index) in sections" :key="index">
        <CustomSection
                :section="section"
                :currentStudio="currentStudio"
                :IsheroBaner="IsheroBaner"
        />
    </template>
</template>
