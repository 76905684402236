<script setup>
import Text from '../elements/Text.vue';
import {defineProps, onMounted} from "vue";
import SectionContainer from '../containers/SectionContainer.vue';
import ElementContaier from '../containers/ElementContaier.vue';
import {loadScript} from "vue-plugin-load-script";

defineProps({
    currentStudio: {
        type: Object
    },
});
onMounted(() => {
   loadScript("https://widgets.mindbodyonline.com/javascripts/healcode.js");
});
</script>

<template>
    <SectionContainer>
        <ElementContaier class="studio-service">
            <Text type="H2" text="Pricing Options" class="element-text text-align-left font-size-64" />
        </ElementContaier>
        <ElementContaier class="studio-service-list">
            <template v-for="(service, index) in currentStudio.mbServices" :key="index">
                <div class="service-box service-box-center">
                    <div class="service-card">
                        <div class="service-content">
                            <Text type="H4" :text="service.Name" class="service-title"/>
                            <h3 class="service-price">
                                <span class="currency">$</span>{{service.OnlinePrice}}
                            </h3>
                            <healcode-widget
                                    data-version="0.2"
                                    data-link-class="healcode-pricing-option-text-link"
                                    :data-site-id="currentStudio.mindbody_web_id"
                                    :data-mb-site-id="currentStudio.mindbody_site_id"
                                    data-type="pricing-link"
                                    data-inner-html="<button type='button' class='btn btn-primary'>Buy Now</button>"
                                    :data-service-id="service.Id"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </ElementContaier>
    </SectionContainer>
</template>