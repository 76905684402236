<script setup>
import { defineProps } from 'vue'

  const props = defineProps({
    rawHtml: String
  })
</script>

<template>
    <div class="rawHtml" :class="props.class" v-html="props.rawHtml" role="heading">
    </div>
</template>
