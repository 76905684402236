<script setup>
    import {Form, Field} from "vee-validate";
    import * as Yup from "yup";
    import {ref} from "vue";
    import Button from "./Button.vue";
    import {customerOptin} from "../../mixins/api";

    const schema = Yup.object().shape({
        firstname: Yup.string().required("First name is required"),
        lastname: Yup.string().required("Last name is required"),
        email: Yup.string().required("Email is required").email("Email is invalid"),
        phonenumber: Yup.string().required("Phone number is required"),
        message: Yup.string().required("Message is required"),
        acceptTerms: Yup.bool().required('Accept Terms is required'),
    });
    const defaultCustomer = {
        firstname: "",
        lastname: "",
        email: "",
        phonenumber: "",
        message: "",
        acceptTerms: undefined,
    };
    const contact = ref(defaultCustomer);

    const contactSuccess = ref('');

    const contactError = ref('');

    const contactFormSubmitting = ref(false);

    const subject = "ILKB Customer Optin - Contact Form";
    const onContactFormSubmit = () => {
        contactFormSubmitting.value = true
        customerOptin(contact.value, subject).then((response) => {
            contactFormSubmitting.value = false
            if (response) {
                console.log("SUCCESS");
                contactSuccess.value = "Thank you. we'll be in contact with you shortly."
                contact.value = {
                    firstname: "",
                    lastname: "",
                    email: "",
                    phonenumber: "",
                    message: "",
                    acceptTerms: undefined,
                };
            } else {
                console.log("ERROR");
                contactError.value = "Please check and try again"
            }
        }).catch(()=>{contactFormSubmitting.value = false});
    };

</script>

<template>
    <div class="form-wrapper contact-form-wrap">
        <Form
                @submit="onContactFormSubmit"
                :validation-schema="schema"
                v-slot="{ errors }"
        >
            <div class="row">
                <template v-if="contactSuccess">
                    <div class="col-100">
                       <div class="alert alert-success">{{contactSuccess}}</div> 
                    </div>
                </template>
                <template v-else-if="contactError">
                    <div class="col-100">
                       <div class="alert alert-danger">{{contactError}}</div> 
                    </div>
                </template>
            </div>
            <div class="row">
                <div class="col-50">
                    <div class="form-group">
                        <Field
                                type="text"
                                v-model="contact.firstname"
                                name="firstname"
                                id="firstname"
                                class="form-control"
                                placeholder=""
                                :class="{ 'is-invalid': errors.firstname }"
                        />
                        <label>First Name</label>
                        <div class="invalid-feedback">{{ errors.firstname }}</div>
                    </div>
                </div>
                <div class="col-50">
                    <div class="form-group">
                        <Field
                                type="text"
                                v-model="contact.lastname"
                                name="lastname"
                                id="lastname"
                                class="form-control"
                                placeholder=""
                                :class="{ 'is-invalid': errors.lastname }"
                        />
                        <label>Last Name</label>
                        <div class="invalid-feedback">{{ errors.lastname }}</div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <Field
                        type="text"
                        v-model="contact.email"
                        name="email"
                        id="email"
                        class="form-control"
                        placeholder=""
                        :class="{ 'is-invalid': errors.email }"
                />
                <label>Email</label>
                <div class="invalid-feedback">{{ errors.email }}</div>
            </div>
            <div class="form-group">
                <Field
                        type="text"
                        v-model="contact.phonenumber"
                        name="phonenumber"
                        id="phonenumber"
                        class="form-control"
                        placeholder=""
                        :class="{ 'is-invalid': errors.phonenumber }"
                />
                <label>Phone Number</label>
                <div class="invalid-feedback">{{ errors.phonenumber }}</div>
            </div>
            <div class="form-group">
                <Field
                        type="text"
                        v-model="contact.message"
                        name="message"
                        id="message"
                        class="form-control"
                        placeholder=""
                        :class="{ 'is-invalid': errors.message }"
                />
                <label>Message</label>
                <div class="invalid-feedback">{{ errors.message }}</div>
            </div>
            <div class="form-group form-check">
                <Field 
                    name="acceptTerms"
                    v-model="contact.acceptTerms"
                    type="checkbox" 
                    id="acceptTerms" 
                    :value="true" 
                    class="form-check-input" 
                    :class="{ 'is-invalid': errors.acceptTerms }" 
                />
                <label for="acceptTerms" class="form-check-label">By submitting this form, I agree to receive emails, text messages and other communications regarding offers and promotions from iLoveKickboxing.</label>
                <div class="invalid-feedback">{{errors.acceptTerms}}</div>
            </div>
            <Button type="submit" text="Submit" class="col-100"/>
        </Form>
    </div>
</template>