<script setup>
import { defineProps } from 'vue';

    const props = defineProps({
        repeats: {
            type: Number
        },
    });

</script>

<template>
    <div class="module-container module-container-tagline">
        <div class="container-fluid">
            <div class="row">
                <section class="section-container col-100">
                    <div class="element-contaier">
                        <div class="judgementfree_group">
                            <div 
                                v-for="(n, index) in props.repeats" 
                                    class="rawHtml judgementfree_text element-text" 
                                    role="heading"
                                    :key=index
                                >
                                <p style="color:#EF3147;font-size:65px;font-family:'pp_formula_condensedbold'">GLOVES ON<span></span></p>
                                <p style="color:#F7F8F9;font-size:85px;font-family:'rogthemregular'">World Off</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>