import {post} from './http_handler';
import gmapsInit from "@/utils/gmaps";
import router from "@/router";

const getAllStudios = async () => {
    return post("/", {
        query:
            `query{
                locations(
                first :500
                active : true
                orderBy: [{field: NAME, order: ASC}]
                ){
                    paginatorInfo {
                        total
                    }
                    data {
                        ID
                          Name
                          lat
                          lng
                          Line1
                          Line2
                          City
                          IsActive
                          state {
                              ID
                              Code
                              State
                              country {
                              ID
                              CountryName
                              CountryCode
                              }
                          }
                          ZipCode
                          phone
                          url_slug
                          schoolBio {
                            schools_bio_id
                            studio_id
                            name
                            description
                            image_link
                            social_link
                            bio_type
                            active_ind
                          }
                    }
                }
            }`,
    })
        .then((response) => {
            return response.data.data.locations;
        })
        .catch((err) => {
            console.log(err);
        });

};

const getNearByStudios = async (lat, lng) => {
    return post("/", {
        query:
            `query zipLocations($lat: String!, $lng: String!, $amount: Int = 5, $active: Boolean = true){
                  zipLocations(lat: $lat, long: $lng, amount: $amount, active: $active) {
                  ID
                  Name
                  lat
                  lng
                  Line1
                  Line2
                  City
                  IsActive
                  landing_page_freeclass_sw
                  state {
                      ID
                      Code
                      State
                      country {
                      ID
                      CountryName
                      CountryCode
                      }
                  }
                  ZipCode
                  phone
                  url_slug
                  schoolBio {
                    schools_bio_id
                    studio_id
                    name
                    description
                    image_link
                    social_link
                    bio_type
                    active_ind
                  }
                  }
              }`,
        variables: {
            "lat": "'" + lat + "'", "lng": "'" + lng + "'"
        }
    })
        .then((response) => {
            return response?.data?.data?.zipLocations;
        })
        .catch((err) => {
            console.log(err);
        });

};

const getLocationsByZipCode = async (zipcode, callback) => {
    const google = await gmapsInit();
    const geocoder = new google.maps.Geocoder();
    if(zipcode.toLowerCase()=="lyman")
        zipcode = zipcode+", U.S.";
    geocoder.geocode({'address': zipcode, 'region': 'USA'}, function (results, status) {
        console.log('Results : ');
        console.log(results);
        if (status !== "OK" || !results[0]) {
            return 'Something went wrong please try again';
        }
        post("/", {
            query:
                `
                            query zipLocations($lat: String!, $lng: String!, $amount: Int = 5, $active: Boolean = true){
                                  zipLocations(lat: $lat, long: $lng, amount: $amount, active: $active) {
                                  ID
                                  Name
                                  lat
                                  lng
                                  Line1
                                  Line2
                                  City
                                  landing_page_freeclass_sw
                                  IsActive
                                  state {
                                      ID
                                      Code
                                      State
                                      country {
                                      ID
                                      CountryName
                                      CountryCode
                                      }
                                  }
                                  ZipCode
                                  phone
                                  url_slug
                                  }
                              }`,
            variables: {
                "lat": "'" + results[0].geometry.location.lat() + "'", "lng": "'" + results[0].geometry.location.lng() + "'"
            }
        })
            .then((response) => {
                callback(response.data.data.zipLocations, results[0].geometry.location.lat(), results[0].geometry.location.lng());
            })
            .catch((err) => {
                console.log(err);
            });
    });

}

const getLocationsById = async (url_slug) => {
    console.log('getLocationsById', url_slug)
    return post("/", {
        query:
            `{ location(slug: "` + url_slug + `"){
                        ID
                        Name
                        Line1
                        Line2
                        City
                        IsActive
                        state{
                            Code
                            State
                            country{
                              CountryCode
                            }
                        }
                        ZipCode
                        County
                        opening_message
                        status
                        phone
                        schools_details
                        staff_email
                        url_slug
                        lat
                        lng
                        mindbody_web_id
                        mindbody_site_id
                        mindbody_schedule_widget
                        landing_page_freeclass_sw
                        referrizer_widget
                        rainmaker_school_id
                        rainmaker_api
                        facebook_link
                        instagram_link
                        twitter_link
                        youtube_link
                        tiktok_link
                        IsActive
                        pos_system
                        products {
                            ckt_products_id
                            type
                            category
                            short_description
                            button_description
                            description
                            internal_product_id
                            price
                            quantity
                            value
                            start_date
                            end_date
                        }
                        mbServices (active_ind: true) {
                            Id
                            studio_id
                            ProductId
                            Price
                            OnlinePrice
                            TaxIncluded
                            TaxRate
                            ProgramId
                            Name
                            Count
                            SellOnline
                            Type
                            ExpirationType
                            ExpirationUnit
                            RevenueCategory
                            MembershipId
                            SellAtLocationIds
                            UseAtLocationIds
                            Priority
                            IsIntroOffer
                            IntroOfferType
                            IsThirdPartyDiscountPricing
                            Program
                            Discontinued
                          }
                          mbContracts (active_ind: true) {
                            Id
                            studio_id
                            Name
                            Description
                            AssignsMembershipId
                            AssignsMembershipName
                            SoldOnline
                            ContractItems {
                              Id
                              Name
                              Description
                              Type
                              Price
                              Quantity
                              OneTimeItem
                            }
                            IntroOffer
                            AutopaySchedule {
                              FrequencyType
                              FrequencyValue
                              FrequencyTimeUnit
                            }
                            NumberOfAutopays
                            AutopayTriggerType
                            ActionUponCompletionOfAutopays
                            ClientsChargedOn
                            ClientsChargedOnSpecificDate
                            DiscountAmount
                            DepositAmount
                            FirstAutopayFree
                            LastAutopayFree
                            ClientTerminateOnline
                            MembershipTypeRestrictions {
                              Id
                              Name
                            }
                            LocationPurchaseRestrictionIds
                            LocationPurchaseRestrictionNames
                            AgreementTerms
                            RequiresElectronicConfirmation
                            AutopayEnabled
                            FirstPaymentAmountSubtotal
                            FirstPaymentAmountTax
                            FirstPaymentAmountTotal
                            RecurringPaymentAmountSubtotal
                            RecurringPaymentAmountTax
                            RecurringPaymentAmountTotal
                            TotalContractAmountSubtotal
                            TotalContractAmountTax
                            TotalContractAmountTotal
                          }
                        googleReviews {
                            author
                            text
                            rating
                            author_img_url
                            status
                            time
                            location {
                                ID
                                Name
                                url_slug
                            }
                        }
                    }
                  }`
    })
        .then((response) => {
            console.log('getLocationsById response', response);
            let currentStudio = response.data.data.location
            if(currentStudio) {
                return currentStudio;
            }
            router.push('/');
        })
        .catch((err) => {
            console.log(err);
        });
}

const getProductsByStudioId = (locationId) => {
    console.log('getProductsByStudioId', locationId)
    return post('/', {
        query: `{
            products(
              location_id: ` + locationId + `
              active: 1
              type: "ilkb checkout"
              category: ["Web Special"]
              first: 2)
            {
              data {
                ckt_products_id
                type
                category
                short_description
                button_description
                description
                internal_product_id
                price
                quantity
                value
                start_date
                end_date
                pos_system_url
              }
            }}`
    })
        .then((response) => {
            return response.data.data.products.data;
        })
        .catch((err) => {
            console.log(err);
        });
}
const getfreeclassByStudioId = (locationId) => {
    console.log('getProductsByStudioId', locationId)
    return post('/', {
        query: `{
            products(
              location_id: ` + locationId + `
              active: 1
              type: "ilkb free"
              category: ["Free Class"]
              first: 2)
            {
              data {
                ckt_products_id
                type
                category
                short_description
                button_description
                description
                internal_product_id
                price
                quantity
                value
                start_date
                end_date
                pos_system_url
              }
            }}`
    })
        .then((response) => {
            return response.data.data.products.data;
        })
        .catch((err) => {
            console.log(err);
        });
}
const getModulesByPageName = (pageName) => {
    console.log('getModulesByPageName', pageName)
    return post('/', {
        query: `query {
              modules(page_name: "` + pageName + `") {
                website_module_id
                module_name
                module_type
                module_background_color
                module_text_color
                module_padding
                module_margin
                is_module_content_full_width
                device
                start_date
                end_date
                production_ind
                active_ind
                moduleSections {
                    website_module_section_id
                    website_module_id
                    device
                    section_alignment
                    section_background_color
                    section_text_color
                    section_padding
                    section_margin
                    section_size
                    section_order
                    moduleSectionElements {
                        website_module_section_element_id
                        website_module_section_id
                        module_section_element_name
                        element_type
                        element_sub_type
                        element_order
                        element_text
                        element_text_alingment
                        element_alt
                        element_text_size
                        element_text_mobile_size
                        element_source
                        element_background_color
                        element_text_color
                        element_padding
                        element_margin
                        element_style
                        element_js
                        device
                        images {
                            website_module_section_element_image_id
                            website_module_section_element_id
                            image_source
                            image_alt
                            device
                        }
                    }
                }
              }
            }`
    })
        .then((response) => {
            console.log('getModulesByPageName', response)
            return response.data.data.modules;
        })
        .catch((err) => {
            console.log('getModulesByPageName', err);
        });
}

const getModuleByModuleId = (moduleId) => {
    console.log('getModuleByModuleId', moduleId)
    return post('/', {
        query: `query {
              moduleDetails(id: ` + moduleId + `) {
                website_module_id
                module_name
                module_type
                module_background_color
                module_text_color
                module_padding
                module_margin
                is_module_content_full_width
                device
                start_date
                end_date
                production_ind
                active_ind
                moduleSections {
                    website_module_section_id
                    website_module_id
                    device
                    section_alignment
                    section_background_color
                    section_text_color
                    section_padding
                    section_margin
                    section_size
                    section_order
                    moduleSectionElements {
                        website_module_section_element_id
                        website_module_section_id
                        module_section_element_name
                        element_type
                        element_sub_type
                        element_order
                        element_text
                        element_text_alingment
                        element_alt
                        element_text_size
                        element_text_mobile_size
                        element_source
                        element_background_color
                        element_text_color
                        element_padding
                        element_margin
                        element_style
                        element_js
                        device
                        images {
                            website_module_section_element_image_id
                            website_module_section_element_id
                            image_source
                            image_alt
                            device
                        }
                    }
                }
              }
            }`
    })
        .then((response) => {
            console.log('getModuleByModuleId', response)
            return [ response.data.data.moduleDetails ];
        })
        .catch((err) => {
            console.log('getModuleByModuleId', err);
        });
}

const fetchGoogleReviews = (studioId = 0) => {
    console.log('fetchGoogleReviews', studioId)
    let studioQuery = '';
    if(studioId > 0){
        studioQuery = `, school_id:` + studioId;
    }

    return post('/', {
        query: `query {
            googleReviews(first: 15, rating: 5` + studioQuery + `, status: "active", orderBy: {column: TIME, order: DESC}) {
                data {
                    schools_google_reviews_id
                    school_id
                    author
                    author_img_url
                    rating
                    text
                    status
                    time
                    location {
                        ID
                        Name
                        url_slug
                    }
                }
            }
        }`
    })
        .then((response) => {
            console.log('fetchGoogleReviews', response)
            return response.data.data.googleReviews.data;
        })
        .catch((err) => {
            console.log('fetchGoogleReviews', err);
        });
}

const fetchStudioSchedule = (studioId = 0, studioType = "In Studio") => {
    console.log('schedule', studioId);

    return post('/', {
        query: `query {
        schedule(location_id: ` + studioId + `, schedule_type: "` + studioType + `") {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          monday_name_time
          tuesday_name_time
          wednesday_name_time
          thursday_name_time
          friday_name_time
          saturday_name_time
          sunday_name_time
        }
      }`
    })
        .then((response) => {
            console.log('fetchStudioSchedule', response)
            return response.data.data.schedule;
        })
        .catch((err) => {
            console.log('fetchStudioSchedule', err);
        });
}


const fetchFAQs = (faqType) => {
    console.log('fetchFAQs')
    return post('/', {
        query: `query {
          schoolfaqs(faq_type: "` + faqType + `") {
            schools_faq_id
            name
            description
            image_link
            video_link
            faq_type
            active_ind
            schools_faq_order
          }
        }`
    })
        .then((response) => {
            console.log('fetchFAQs', response)
            return response.data.data.schoolfaqs;
        })
        .catch((err) => {
            console.log('fetchFAQs', err);
        });
}

const fetchBios = (faqType, studioId) => {
    console.log('fetchBios')
    return post('/', {
        query: `query {
          schoolbios(
          bio_type: "` + faqType + `"
          studio_id: ` + studioId + `
          enabled_ind: 1
          ) {
            schools_bio_id
            studio_id
            name
            description
            image_link
            social_link
            social_type
            bio_type
            active_ind
            enabled_ind
          }
        }`
    })
        .then((response) => {
            console.log('fetchBios', response)
            return response.data.data.schoolbios;
        })
        .catch((err) => {
            console.log('fetchBios', err);
        });
}

const fetchSchoolBio = (faqType, studioId) => {
    console.log('fetchSchoolBio')
    return post('/', {
        query: `query {
          schoolbios(
          bio_type: "` + faqType + `"
          studio_id: ` + studioId + `
          ) {
            schools_bio_id
            studio_id
            name
            description
            image_link
            social_link
            bio_type
            active_ind
          }
        }`
    })
        .then((response) => {
            console.log('fetchSchoolBio', response)
            return response.data.data.schoolbios;
        })
        .catch((err) => {
            console.log('fetchSchoolBio', err);
        });
}

const KlaviyotrackEvent = (customer, studio) => {
    const checkoutUrl = process.env.VUE_APP_CHECKOUT_URL;
    const signupUrl = process.env.VUE_APP_SIGNUP_URL;

    return post('/', {
        query: `mutation{ 
            KlaviyotrackEvent( input: { 
                event: "ILKBOptin", 
                customer: [
                    {name: "$email", value: "` + customer.email + `"}
                    {name: "$first_name", value: "` + customer.firstname + `"}
                    {name: "$last_name", value: "` + customer.lastname + `"}
                    {name: "name", value: "` + customer.firstname + ` ` + customer.lastname + `"}
                    {name: "$phone_number", value: "` + customer.phonenumber + `"}
                    {name: "email", value: "` + customer.email + `"}
                    {name: "signup_url", value: "` + signupUrl + studio.url_slug + `"}
                    {name: "checkout_url", value: "` + checkoutUrl + studio.url_slug + `"}
                    {name: "source", value: "ilkb_website_optin"}
                    {name: "mindbody_member", value: "No"}
                    {name: "location_name", value: "` + studio.Name + `"}
                    {name: "location_slug", value: "` + studio.url_slug + `"}
                    {name: "utm_agency", value: "ilkbws"}
                    {name: "utm_source", value: "website"}
                    {name: "utm_offer", value: "WebSpecial"}
                ], 
                properties: [
                    {name: "source_url", value: "` + signupUrl + studio.url_slug + `"}
                    {name: "signup_url", value: "` + signupUrl + studio.url_slug + `/signup"}
                    {name: "checkout_url", value: "` + checkoutUrl + studio.url_slug + `"}
                    {name: "source", value: "ilkb_website_optin"}
                    {name: "mindbody_member", value: "No"}
                    {name: "location_name", value: "` + studio.Name + `"}
                    {name: "utm_agency", value: "ilkbws"}
                    {name: "utm_source", value: "website"}
                    {name: "utm_offer", value: "WebSpecial"}
                    {name: "other_tags", value: "ilkb_website_optin"}
                ]
            } )
        }`
    })
        .then((response) => {
            console.log('KlaviyotrackEvent', response)
            return response.data;
        })
        .catch((err) => {
            console.log('KlaviyotrackEvent', err);
        });
}

const customerOptin = (customer, subject) => {
    console.log('customerOptin')
    return post('/', {
        query: `mutation{ 
            recordCustomerOptin(input: { 
                first_name: "` + customer.firstname + `"
                last_name: "` + customer.lastname + `"
                email: "` + customer.email + `"
                phone: "` + customer.phonenumber + `"
                subject: "` + subject + `"
                message: "` + customer.message + `"
            })
            {
                status
            }
        }`
    })
        .then((response) => {
            console.log('customerOptin', response)
            return response?.data?.data?.recordCustomerOptin?.status;
        })
        .catch((err) => {
            console.log('customerOptin Error', err);
        });
}

const customerInfoLead = (customer, schoolId) => {
    console.log('customerInfoLead')
    return post('/', {
        query :`mutation {
            emailandRegisterCustomerInfo(input : { school_id: `+schoolId+`, 
            first_name:  "` + customer.first_name + `"
            last_name:"` + customer.last_name + `"
            telephone: "` + customer.telephone + `"
            email:"` + customer.email + `"
            email_subscription:` + customer.email_subscription + `
            sms_subscription:` + customer.sms_subscription + `}) 
            {
                sent
            }
          }`
    })
        .then((response) => {
            console.log('customerInfoLead', response)
            return response?.data?.data?.emailandRegisterCustomerInfo?.sent;
        })
        .catch((err) => {
            console.log('customerInfoLead Error', err);
        });
}
const EmailIntroOfferVisit = (customer, studio) => {
    console.log('EmailIntroOfferVisit')
    return post('/', {
        query :`mutation {
            EmailIntroOfferVisit(input : { school_id: `+studio.ID+`, 
            first_name:  "` + customer.firstname + `"
            last_name:"` + customer.lastname + `"
            telephone: "` + customer.phonenumber + `"
            email:"` + customer.email + `"}) 
            {
                sent
            }
          }`
    })
        .then((response) => {
            console.log('customerInfoLead', response)
            return response?.data?.data?.emailandRegisterCustomerInfo?.sent;
        })
        .catch((err) => {
            console.log('customerInfoLead Error', err);
        });
}

export {
    getAllStudios,
    getNearByStudios,
    getLocationsByZipCode,
    getLocationsById,
    getProductsByStudioId,
    getModulesByPageName,
    getModuleByModuleId,
    fetchGoogleReviews,
    fetchStudioSchedule,
    fetchFAQs,
    fetchBios,
    fetchSchoolBio,
    KlaviyotrackEvent,
    customerOptin,
    customerInfoLead,
    EmailIntroOfferVisit,
    getfreeclassByStudioId
}