<script setup>
    import {defineProps, onMounted} from "vue";
    import HtmlComponent from "./HtmlComponent.vue";

    defineProps({
        widget: {
            type: String
        },
    });

    onMounted(() => {
        let widgetScript = document.createElement('script')
        widgetScript.setAttribute('src', 'https://widget.referrizer.com/offer/popup-voucher.js')
        document.head.appendChild(widgetScript)
    });
</script>
<template>
    <div class="referrizer-widget">
        <HtmlComponent :rawHtml="widget" />
    </div>
</template>