<script setup>
import {computed, defineProps} from 'vue'

  const props = defineProps({
    text: String,
    src: String,
    width: {
      type: String,
      default: '1920',
    },
    height: {
      type: String,
      default: '1080',
    },
  })

// Element Source
const elementSource = computed(() => {
  let youtubeShortSource = 'https://youtu.be/';
  let youtubeEmbedSource = 'https://www.youtube.com/embed/';
  return (props.src) ? props.src.replace(youtubeShortSource, youtubeEmbedSource) : '';
});

</script>

<template>
  <div class="videolink-box">
    <iframe 
      :width="props.width" 
      :height="props.height"
      :src="elementSource+'?controls=0&showinfo=0&rel=0&autoplay=0&loop=1&mute=0'"
      :title="props.text" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen
    >
    </iframe>
  </div>
</template>