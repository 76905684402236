<script setup>
    import {ref, onMounted, defineEmits} from "vue";
    import {Form, Field} from "vee-validate";
    import * as Yup from "yup";
    import Button from "./Button.vue";
    import {getLocationsByZipCode, getNearByStudios} from "../../mixins/api";
    import Text from "@/components/elements/Text.vue";

    const emit= defineEmits(["selectedStudio"])
    const schema = Yup.object().shape({
        zipcode: Yup.string().required("State, City, Postal or Zip Code is required"),
    });
    const customer = ref({
        zipcode: "",
    });
    const studios = ref([]);
    const locations = ref({});
    const searchDropdown = ref();
    const locationSlug = ref();
    const onSubmit = () => {
        getLocationsByZipCode(customer.value.zipcode, studioResults);
        searchDropdown.value = true;
    };
    const studioResults = (response) => {
        studios.value = response;
    }

    const getLatLang = () => {
        return new Promise((resolve) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    let pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    locations.value = pos;
                    resolve();
                }, function () {
                    console.log('Location cannot be found');
                    resolve();
                })
            } else {
                resolve();
            }
        })
    };

    const getStudios = () => {
        return getNearByStudios(locations.value.lat, locations.value.lng);
    };

    const selectedLocationSlug = (studio) => {
        emit('selectedStudio', studio)
    };

    onMounted(() => {
        getLatLang().then(() => {
            if (locations.value.lat && locations.value.lng) {
                getStudios().then(studioResults);
            }
        });
    });
</script>

<template>
    <div class="zipcode-form-main">
        <div class="zipcode_form">
            <Form
                    @submit="onSubmit"
                    :validation-schema="schema"
                    v-slot="{ errors }"
            >
                <div class="">
                    <div class="hero_zipcode_field">
                        <Field
                                type="text"
                                v-model="customer.zipcode"
                                name="zipcode"
                                id="zipcode"
                                class="form-control"
                                placeholder="Enter your State, City, Postal Code, or Zip Code"
                                :class="{ 'is-invalid': errors.zipcode }"
                        />
                        <div class="invalid-feedback">{{ errors.zipcode }}</div>
                        <Button type="submit" text="Search">
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
        <template v-if="searchDropdown">
            <div class="findstudio-search-dropdown-wrapper" v-if="studios.length > 0">
                <!-- <Text type="SPAN" text="Your closest studio:" class="yourclosest-title" /> -->
                <div class="findstudio-search-dropdown-card" v-for="(studio) in studios" :key="studio.ID">
                    <div class="findstudio-search-dropdown-text" v-if="studio.IsActive == true ">
                        <div class="form-group">
                            <Field
                                type="radio"
                                :checked="locationSlug"
                                name="locationSlug"
                                v-model="locationSlug"
                                class="form-control-radio"
                                :id="'findstudio' + studio.ID"
                                :value="studio.url_slug"
                                @change="selectedLocationSlug(studio)"
                            />
                            <label class="form-label" :for="'findstudio' + studio.ID">
                                <Text type="H4" :text="studio.City+', '+studio.state.Code"/>
                                <Text :text="studio.Line1"/>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>