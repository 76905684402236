<script setup>

    import {onBeforeMount, defineProps, ref, onMounted} from "vue";
    
    const props = defineProps({
        widthSize: String,
        padding: String,
        margin: String,
        class: String,
    })
    const isMobile = ref(false);
    const checkIsMobile = () => {
        let width = window.innerWidth || 
        document.documentElement.clientWidth || 
        document.body.clientWidth;
        isMobile.value = width < 640 ? true : false;
    };

    onBeforeMount(() => {
        window.addEventListener("resize", checkIsMobile);
    });
    onMounted(() => {
        checkIsMobile();
    });
</script>
<template>
    <section 
        class="section-container"
        :class="[props.widthSize ? 'col-'+ props.widthSize : 'col-100', props.class, isMobile ? 'mobile-device' : '' ]"
        :style="{
            padding: props.padding, 
            margin: props.margin,
        }"
    >
        <slot></slot>
    </section>
</template>
