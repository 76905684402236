<script setup>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { defineProps, onBeforeMount, ref, onMounted } from "vue";
import Text from "../elements/Text.vue";
import FooterCopyright from "../elements/FooterCopyright.vue";
import CloseIcon from "../../assets/svg/CloseIcon.vue";
import GetStarted from "../elements/GetStarted.vue";
import Modal from "../elements/Modal.vue";
import Cookies from "../elements/Cookies.vue";

const props = defineProps({
  currentStudio: {
    type: Object
  },
  queryParams: {
    type: Object
  }
});
const isMobile = ref(false);

const schema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is invalid"),
});
const customer = ref({
  email: "",
});
const onSubmit = () => {
  sessionStorage.setItem('email', customer.value.email)
  showModalOption();
};

const checkIsMobile = () => {
  let width = window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  isMobile.value = width < 640 ? true : false;
};
const isModalOption = ref(false);
const showModalOption = () => {
  isModalOption.value = true;
};
const closeModalOption = () => {
  isModalOption.value = false;
};
onBeforeMount(() => {
  window.addEventListener("resize", checkIsMobile);
});
onMounted(() => {
  console.log('Footer currentStudio', props.currentStudio)
  checkIsMobile();
});

const aboutnavs = ref([
  { name: 'About Us', to: { path: '/about', query: props?.queryParams }, is_external: false },
  { name: 'Careers', to: process.env.VUE_APP_CAREERS_URL, is_external: true },
  /*{name: 'Shop', to: '', is_external: false},*/
  { name: 'Studio Finder', to: { path: '/find-location', query: props?.queryParams }, is_external: false },
  { name: 'Own a Franchise', to: process.env.VUE_APP_FRANCHISE_URL, is_external: true },
]);
const supportnavs = ref([
  { name: 'Contact', to: { path: '/contact', query: props?.queryParams }, is_external: false },
  { name: 'FAQ', to: { path: '/faqs', query: props?.queryParams }, is_external: false },
  { name: 'Privacy Policy', to: { path: '/privacy-policy', query: props?.queryParams }, is_external: false },
  { name: 'Terms of Service', to: { path: '/terms-of-use', query: props?.queryParams }, is_external: false },
  { name: 'Accessibility Statement', to: { path: '/accessibility-statement', query: props?.queryParams }, is_external: false },
]);
const resourcesnavs = ref([
  { name: 'Gift Certificates', to: process.env.VUE_APP_GIFT_CERTIFICATE_URL, is_external: true },
  /*{name: 'COVID19', to: '/coronavirus', is_external: false},*/
])
</script>
<template>

  <footer class="footer-wrapper" id="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-25 ft_brandinfo_order">
          <div class="footer_logo">
            <template v-if="isMobile">
              <img class="img-fluid"
                src="https://res.cloudinary.com/ilovekickboxing/image/upload/f_auto,fl_lossy,q_auto:eco/v1/Brand/logo-main-white-1.png"
                alt="iLoveKickboxing Logo" />
            </template>
            <template v-else>
              <svg width="169" height="44" viewBox="0 0 169 44" fill="none" xmlns="http://www.w3.org/2000/svg" role="presentation">
                <g clip-path="url(#clip0_1158_1132)">
                  <path d="M0 20.0459V0.000244141H4.85647V20.0459H0Z" fill="#F7F8F9" />
                  <path d="M13.3291 20.0459V0.000244141H18.1856V15.8202H25.9454V20.0459H13.3291Z" fill="#F7F8F9" />
                  <path
                    d="M57.1161 20.0459H52.286L44.6846 0.000244141H50.1217L54.767 14.2091H54.8726L59.4915 0.000244141H64.8495L57.1161 20.0459Z"
                    fill="#F7F8F9" />
                  <path
                    d="M66.248 20.0459V0.000244141H79.7089V4.09388H70.9198V7.87059H79.2338V11.7529H70.9198V15.9258H80.2368V20.0459H66.248Z"
                    fill="#F7F8F9" />
                  <path
                    d="M12.2468 43.4457L4.80369 34.2284H4.7509V43.4457H0V23.4265H4.7509V31.5874H4.83008L11.9828 23.4265H18.027L9.39622 32.6438L18.5285 43.4457H12.2468Z"
                    fill="#F7F8F9" />
                  <path d="M19.6631 43.4457V23.4265H24.5196V43.4721H19.6631V43.4457Z" fill="#F7F8F9" />
                  <path
                    d="M37.004 44.0003C30.8279 44.0003 26.2354 39.6954 26.2354 33.4096C26.2354 26.9919 30.9862 22.8718 37.1096 22.8718C39.9074 22.8718 42.9427 23.9018 44.6055 25.8034L41.3326 29.0783C40.4353 27.837 38.8252 27.2296 37.2944 27.2296C33.916 27.2296 31.5141 29.8442 31.5141 33.3832C31.5141 37.0279 33.8632 39.5369 37.1888 39.5369C39.0892 39.5369 40.5936 38.6918 41.4382 37.5033L44.8166 40.6725C43.0219 42.7062 40.3825 44.0003 37.004 44.0003Z"
                    fill="#F7F8F9" />
                  <path
                    d="M58.5417 43.4457L51.0986 34.2284H51.0458V43.4457H46.2949V23.4265H51.0458V31.5874H51.125L58.2778 23.4265H64.322L55.6912 32.6438L64.8234 43.4457H58.5417Z"
                    fill="#F7F8F9" />
                  <path
                    d="M74.0345 43.4457H66.0635V23.4265H74.0345C77.149 23.4265 80.8441 24.5358 80.8441 28.5766C80.8441 30.7951 79.5244 32.2476 77.5713 32.9079V32.9607C79.8675 33.3569 81.6623 34.9943 81.6623 37.6354C81.6623 41.9931 77.8352 43.4457 74.0345 43.4457ZM73.0843 27.256H70.7089V31.3761H73.3219C75.143 31.3761 76.1724 30.6102 76.1724 29.184C76.1724 27.8635 75.1166 27.256 73.0843 27.256ZM73.401 34.9679H70.7089V39.5634H73.4274C74.9583 39.5634 76.8323 39.1408 76.8323 37.1864C76.8323 35.4961 75.4598 34.9679 73.401 34.9679Z"
                    fill="#F7F8F9" />
                  <path
                    d="M93.9358 44.0003C87.6805 44.0003 83.0352 39.6954 83.0352 33.3568C83.0352 26.9391 87.6805 22.8718 93.9358 22.8718C100.218 22.8718 104.863 26.9126 104.863 33.3568C104.863 39.6954 100.218 44.0003 93.9358 44.0003ZM93.9358 27.2296C90.531 27.2296 88.2347 29.8442 88.2347 33.3304C88.2347 36.9487 90.5838 39.5633 93.9358 39.5633C97.3142 39.5633 99.6897 36.9487 99.6897 33.3304C99.6897 29.8442 97.3406 27.2296 93.9358 27.2296Z"
                    fill="#F7F8F9" />
                  <path
                    d="M117.005 43.4457L112.623 36.3677L108.4 43.4457H102.62L109.641 32.8551L103.201 23.4265H109.034L112.755 29.6594L116.424 23.4265H122.046L115.711 32.8815L122.943 43.4721H117.005V43.4457Z"
                    fill="#F7F8F9" />
                  <path d="M124.025 43.4457V23.4265H128.881V43.4721H124.025V43.4457Z" fill="#F7F8F9" />
                  <path
                    d="M143.292 43.4457L135.242 30.3461H135.136L135.242 43.4457H130.544V23.4265H136.06L144.084 36.4997H144.163L144.058 23.4265H148.756V43.4721H143.292V43.4457Z"
                    fill="#F7F8F9" />
                  <path
                    d="M161.293 43.9739C154.906 43.9739 150.287 39.7218 150.287 33.4096C150.287 26.9919 155.038 22.8718 161.161 22.8718C164.302 22.8718 167.206 23.9282 168.895 25.5393L165.833 29.0255C164.909 27.9427 163.299 27.1503 161.346 27.1503C157.941 27.1503 155.46 29.8178 155.46 33.4096C155.46 37.0807 157.651 39.7218 161.557 39.7218C162.692 39.7218 163.748 39.5633 164.593 39.1936V35.5489H160.739V31.6401H169V42.2572C167.047 43.2608 164.381 43.9739 161.293 43.9739Z"
                    fill="#F7F8F9" />
                  <path
                    d="M33.5995 19.3063L42.679 10.2211C43.8139 9.08543 44.421 7.58003 44.421 5.9954C44.421 4.41076 43.7875 2.90536 42.679 1.76971C41.5441 0.634054 40.0396 0.0266114 38.456 0.0266114C36.8459 0.0266114 35.3679 0.660465 34.233 1.76971L32.8605 3.14306L31.488 1.76971C29.1653 -0.554421 25.3646 -0.554421 23.0156 1.76971C20.6929 4.09384 20.6929 7.89696 23.0156 10.2475L32.8605 20.0986L33.5995 19.3063Z"
                    fill="#EF3147" />
                </g>
                <defs>
                  <clipPath id="clip0_1158_1132">
                    <rect width="169" height="44" fill="white" transform="translate(0 0.000244141)" />
                  </clipPath>
                </defs>
              </svg>
            </template>
          </div>
          <div class="socialmedia">
            <a 
              :href="`https://www.facebook.com/` + (currentStudio?.facebook_link ? currentStudio?.facebook_link : 'ilovekickboxing')"
              class="social-icon" aria-label="Facebook"
              target="_blank"
            >
              <svg width="10" height="18" viewBox="0 0 10 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.34473 10.1247L9.86346 6.86724H6.62096V4.75337C6.62096 3.86237 7.07375 2.99331 8.52608 2.99331H10V0.220182C10 0.220182 8.66262 0.000244141 7.38359 0.000244141C4.7135 0.000244141 2.96826 1.56062 2.96826 4.38493V6.86781H0V10.1252H2.96826V18.0002H6.62096V10.1252L9.34473 10.1247Z" />
              </svg>
            </a>
            <a
              :href="`https://www.instagram.com/` + (currentStudio?.instagram_link ? currentStudio?.instagram_link : 'ilovekickboxing')"
              class="social-icon" aria-label="Instagram"
              target="_blank"
            >
              <svg width="19" height="18" viewBox="0 0 19 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg" role="presentation">
                <path d="M9.49745 5.99897C7.75305 5.99897 6.32949 7.34763 6.32949 9.00024C6.32949 10.6529 7.75305 12.0015 9.49745 12.0015C11.2418 12.0015 12.6654 10.6529 12.6654 9.00024C12.6654 7.34763 11.2418 5.99897 9.49745 5.99897ZM18.9989 9.00024C18.9989 7.7574 19.0108 6.52582 18.9371 5.28523C18.8635 3.84426 18.5165 2.5654 17.4043 1.51168C16.2897 0.455721 14.9421 0.129251 13.4211 0.0594534C12.1093 -0.0103438 10.8093 0.000913842 9.49982 0.000913842C8.18796 0.000913842 6.88798 -0.0103438 5.5785 0.0594534C4.0575 0.129251 2.70761 0.457973 1.59538 1.51168C0.480772 2.56765 0.136171 3.84426 0.0624975 5.28523C-0.0111759 6.52807 0.000706894 7.75966 0.000706894 9.00024C0.000706894 10.2408 -0.0111759 11.4747 0.0624975 12.7153C0.136171 14.1562 0.483149 15.4351 1.59538 16.4888C2.70999 17.5448 4.0575 17.8712 5.5785 17.941C6.89036 18.0108 8.19034 17.9996 9.49982 17.9996C10.8117 17.9996 12.1117 18.0108 13.4211 17.941C14.9421 17.8712 16.292 17.5425 17.4043 16.4888C18.5189 15.4328 18.8635 14.1562 18.9371 12.7153C19.0132 11.4747 18.9989 10.2431 18.9989 9.00024ZM9.49745 13.6181C6.80005 13.6181 4.62312 11.5557 4.62312 9.00024C4.62312 6.44477 6.80005 4.38237 9.49745 4.38237C12.1948 4.38237 14.3718 6.44477 14.3718 9.00024C14.3718 11.5557 12.1948 13.6181 9.49745 13.6181ZM14.5714 5.27172C13.9416 5.27172 13.433 4.7899 13.433 4.19325C13.433 3.59659 13.9416 3.11477 14.5714 3.11477C15.2012 3.11477 15.7098 3.59659 15.7098 4.19325C15.71 4.33492 15.6806 4.47524 15.6235 4.60617C15.5664 4.7371 15.4825 4.85606 15.3768 4.95624C15.271 5.05642 15.1455 5.13586 15.0073 5.18999C14.8691 5.24413 14.721 5.2719 14.5714 5.27172Z"/>
              </svg> 
            </a>
            <a
              :href="`https://www.twitter.com/` + (currentStudio?.twitter_link ? currentStudio?.twitter_link : 'ilovekickboxing')"
              class="social-icon" aria-label="Twitter"
              target="_blank"
            >
              <svg width="19" height="16" viewBox="0 0 19 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" role="presentation">
                <path d="M18.4263 2.28733C17.7487 2.58759 17.0207 2.79047 16.2555 2.88217C17.0451 2.4097 17.6358 1.66608 17.9175 0.79008C17.1756 1.23072 16.3637 1.54089 15.517 1.70709C14.9476 1.09916 14.1935 0.696209 13.3716 0.560806C12.5498 0.425403 11.7062 0.565121 10.9719 0.958269C10.2376 1.35142 9.65361 1.976 9.31065 2.73504C8.96768 3.49409 8.88491 4.34513 9.07518 5.15604C7.572 5.08056 6.10149 4.68986 4.75907 4.00929C3.41666 3.32871 2.23235 2.37348 1.28301 1.20558C0.9584 1.76552 0.771752 2.41474 0.771752 3.10615C0.77139 3.72858 0.924668 4.34147 1.21799 4.89045C1.51131 5.43944 1.9356 5.90753 2.45321 6.25321C1.85292 6.23411 1.26586 6.0719 0.740914 5.78009V5.82878C0.740854 6.70176 1.04282 7.54788 1.59559 8.22356C2.14835 8.89925 2.91786 9.36288 3.77355 9.53579C3.21667 9.6865 2.63283 9.7087 2.06612 9.60071C2.30754 10.3519 2.77782 11.0087 3.4111 11.4793C4.04438 11.9499 4.80898 12.2107 5.59784 12.2252C4.2587 13.2764 2.60487 13.8466 0.902406 13.8441C0.600831 13.8442 0.299511 13.8266 0 13.7914C1.7281 14.9025 3.73974 15.4922 5.79422 15.4899C12.7489 15.4899 16.5509 9.72974 16.5509 4.73405C16.5509 4.57174 16.5468 4.40782 16.5395 4.24552C17.279 3.71071 17.9174 3.04844 18.4247 2.28976L18.4263 2.28733Z"/>
              </svg>
            </a>
            <a
              :href="`https://www.youtube.com/` + (currentStudio?.youtube_link ? currentStudio?.youtube_link : 'ilovekickboxingfitness')"
              class="social-icon" aria-label="Youtube"
              target="_blank"
            >
              <svg width="22" height="16" viewBox="0 0 22 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" role="presentation">
                <path d="M21.4783 3.07957C21.3565 2.64531 21.1194 2.25216 20.7921 1.9419C20.4555 1.6221 20.043 1.39334 19.5935 1.27721C17.9112 0.833788 11.1711 0.833788 11.1711 0.833788C8.36127 0.801819 5.55211 0.942426 2.7595 1.25482C2.30998 1.37952 1.89824 1.6134 1.56091 1.93563C1.22946 2.25454 0.989389 2.64779 0.863976 3.07868C0.56271 4.70165 0.416334 6.34957 0.426822 8.00024C0.416073 9.64941 0.562089 11.2968 0.863976 12.9218C0.986701 13.3509 1.22588 13.7424 1.55823 14.0586C1.89057 14.3748 2.30443 14.6032 2.7595 14.7242C4.46422 15.1667 11.1711 15.1667 11.1711 15.1667C13.9845 15.1987 16.7973 15.0581 19.5935 14.7457C20.043 14.6295 20.4555 14.4008 20.7921 14.081C21.1235 13.7647 21.3591 13.3715 21.4774 12.9433C21.7865 11.3209 21.9368 9.67234 21.9262 8.02084C21.9494 6.36235 21.7993 4.70597 21.4783 3.07868V3.07957ZM9.02835 11.0675V4.93389L14.6361 8.00113L9.02835 11.0675Z"/>
              </svg>
            </a>
              <a
                      :href="`https://www.tiktok.com/` + (currentStudio?.tiktok_link ? currentStudio?.tiktok_link : '@ilovekickboxing')"
                      class="social-icon" aria-label="Tiktok"
                      target="_blank"
              >
                  <svg width="14" height="18" viewBox="0 0 448 512" fill="currentColor" xmlns="http://www.w3.org/2000/svg" role="presentation">
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/>
                  </svg>
              </a>
          </div>
          <template v-if="!isMobile">
            <FooterCopyright />
          </template>
        </div>
        <div class="col-50 ft_quicklink_order">
          <div class="row">
            <div class="col-33">
              <div class="ft_bx">
                <Text type="H3" text="ABOUT" />
                <ul class="nav">
                  <li 
                    v-for='(aboutnav) in aboutnavs' 
                    :key='aboutnav.id'
                    class="nav-item" 
                  >
                    <a :href="aboutnav.to" target="_blank" class="nav-link" v-if="aboutnav.is_external" :aria-label="aboutnav.name + ' (opens in a new tab)'">
                      {{ aboutnav.name }}
                    </a>
                    <router-link :to="aboutnav.to" class="nav-link" v-else>{{ aboutnav.name }}</router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-33">
              <div class="ft_bx">
                <Text type="H3" text="SUPPORT" />
                <ul class="nav">
                  <li 
                    v-for='(supportnav) in supportnavs' 
                    :key='supportnav.id'
                    class="nav-item" 
                  >
                    <a :href="supportnav.to" target="_blank" class="nav-link" v-if="supportnav.is_external"    :aria-label="aboutnav.name + ' (opens in a new tab)'">
                      {{ supportnav.name }}
                    </a>
                    <router-link :to="supportnav.to" class="nav-link" v-else>{{ supportnav.name }}</router-link>
                  </li>
                </ul>
              </div>
            </div>
            <!-- <div class="col-33">
              <div class="ft_bx">
                <Text type="H3" text="Resources" />
                <ul class="nav">
                  <li v-for='(resourcesnav) in resourcesnavs' :key='resourcesnav.id' class="nav-item">
                    <a :href="resourcesnav.to" target="_blank" class="nav-link" v-if="resourcesnav.is_external">
                      {{ resourcesnav.name }}
                    </a>
                    <router-link :to="resourcesnav.to" class="nav-link" v-else>{{ resourcesnav.name }}</router-link>
                  </li>
                </ul>
              </div>
            </div> -->
          </div>
        </div>
        <div class="col-25 ft_newsletter_order">
          <div class="ft_bx">
            <Text type="H3" text="Newsletter" />
            <!-- <div class="newsletter-enter">
              <button type="button" class="btn" @click="showModalOption">
                <Text type="H2" text="your email" class="newsletter-title" />
                <svg width="17" height="15" viewBox="0 0 17 15" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1395 0.852615C9.74896 0.46209 9.11579 0.46209 8.72527 0.852615C8.33474 1.24314 8.33474 1.8763 8.72527 2.26683L12.7452 6.28677L1.41479 6.28677C0.862509 6.28677 0.414794 6.73449 0.414794 7.28677C0.414794 7.83906 0.862509 8.28677 1.41479 8.28677H12.7446L8.72527 12.3061C8.33474 12.6966 8.33474 13.3298 8.72527 13.7203C9.11579 14.1108 9.74896 14.1108 10.1395 13.7203L15.8552 8.00458C15.8922 7.96864 15.9265 7.92985 15.9577 7.88857C16.0234 7.80154 16.0731 7.70621 16.1067 7.60665C16.118 7.57312 16.1276 7.5388 16.1353 7.50379C16.2068 7.18128 16.1171 6.83024 15.8662 6.57934L10.1395 0.852615Z"/>
                </svg>
              </button>
            </div> -->
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
              <div class="">
                <div class="newsletter_field">
                  <Field
                      type="text"
                      v-model="customer.email"
                      name="email"
                      id="email"
                      class="form-control"
                      placeholder="your email"
                      aria-label="Email address"
                      aria-required="true"
                      :class="{ 'is-invalid': errors.email }"
                  />
                  <div class="invalid-feedback">{{ errors.email }}</div>
                  <button type="submit" class="btn" aria-label="Submit">
                    <svg width="17" height="15" viewBox="0 0 17 15" fill="currentColor" xmlns="http://www.w3.org/2000/svg" role="presentation">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1395 0.852615C9.74896 0.46209 9.11579 0.46209 8.72527 0.852615C8.33474 1.24314 8.33474 1.8763 8.72527 2.26683L12.7452 6.28677L1.41479 6.28677C0.862509 6.28677 0.414794 6.73449 0.414794 7.28677C0.414794 7.83906 0.862509 8.28677 1.41479 8.28677H12.7446L8.72527 12.3061C8.33474 12.6966 8.33474 13.3298 8.72527 13.7203C9.11579 14.1108 9.74896 14.1108 10.1395 13.7203L15.8552 8.00458C15.8922 7.96864 15.9265 7.92985 15.9577 7.88857C16.0234 7.80154 16.0731 7.70621 16.1067 7.60665C16.118 7.57312 16.1276 7.5388 16.1353 7.50379C16.2068 7.18128 16.1171 6.83024 15.8662 6.57934L10.1395 0.852615Z"/>
                    </svg>
                  </button>
                </div>
              </div>
            </Form>
            <Text text="Get news, promo offers and more to your inbox. No spam!" />
          </div>
        </div>
      </div>
      <template v-if="isMobile">
        <div class="row">
          <div class="col-100">
            <FooterCopyright />
          </div>
        </div>
      </template>
      <Modal modalLarge="true" v-if="isModalOption">
        <template #body>
          <span class="modal-close" @click="closeModalOption">
            <CloseIcon />
          </span>
          <GetStarted :redirectedToCheckout="false" :redirectedToStudio="true" />
        </template>
      </Modal>
    </div>
  </footer>
  <Cookies />
</template>
<script>
(function () {
  var s = document.createElement("script");
  var h = document.querySelector("head") || document.body;
  s.src = "https://acsbapp.com/apps/app/dist/js/app.js";
  s.async = true;
  s.onload = function () {
    acsbJS.init({
      statementLink: "",
      footerHtml: "",
      hideMobile: false,
      hideTrigger: false,
      disableBgProcess: false,
      language: "en",
      position: "right",
      leadColor: "#146FF8",
      triggerColor: "#146FF8",
      triggerRadius: "50%",
      triggerPositionX: "right",
      triggerPositionY: "bottom",
      triggerIcon: "people",
      triggerSize: "bottom",
      triggerOffsetX: 20,
      triggerOffsetY: 20,
      mobile: {
        triggerSize: "small",
        triggerPositionX: "right",
        triggerPositionY: "bottom",
        triggerOffsetX: 10,
        triggerOffsetY: 10,
        triggerRadius: "20",
      },
    });
  };
  h.appendChild(s);
})();
</script>
