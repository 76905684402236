<script setup>
    import Text from "@/components/elements/Text.vue";
    import {ref, defineProps, onMounted} from "vue";
    import {fetchFAQs} from "../../mixins/api";
    import '../../assets/scss/faqs.scss';

    const props = defineProps({
        type: {
            type: String,
            required: true
        }
    });

    const faqs = ref([]);
    const activeIndex = ref();
    const getFAQs = () => {
        fetchFAQs(props?.type).then((response) => {
            faqs.value = response;
        });
    };
    onMounted(() => {
        getFAQs();
    });
</script>
<template>
    <div class="faqs-main-wrp" v-if="faqs.length > 0">
        <div 
            class="faqs-item-card" 
            v-for="(faq, faqsIndex) in faqs" 
            :key="'faq-item' + faqsIndex"
        >
            <div 
                class="faqs-item-question"
                :class="{'faqs-item-question-active': activeIndex === faqsIndex}" 
                @click='activeIndex = activeIndex === faqsIndex ? null : faqsIndex'
            >
                <Text :text="faq?.name" type="H5" />
                <Text 
                    type="SPAN" 
                    :text="faqsIndex === activeIndex ? '-' : '+'"
                    class="faqs-addminus"
                />
            </div>
            <transition
                name="fade"
            >
                <div class="faqs-item-answer" v-show="faqsIndex === activeIndex">
                    <Text :text="faq?.description" />
                </div>
            </transition>
        </div>
    </div>
</template>