<script setup>
import {Splide, SplideSlide} from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import Text from '../elements/Text.vue';
import { onBeforeMount, ref, onMounted } from "vue";
import {fetchGoogleReviews} from "../../mixins/api";
import Image from '../elements/Image.vue';
import SectionContainer from '../containers/SectionContainer.vue';
import ElementContaier from '../containers/ElementContaier.vue';

const isMobile = ref(false);
const mainslider   = ref();
const thumbnailsslider = ref();

const checkIsMobile = () => {
  let width = window.innerWidth || 
    document.documentElement.clientWidth || 
    document.body.clientWidth;
  isMobile.value = width < 640 ? true : false;
};

const testimonials = ref([]);

const getGoogleReviews = () => {
    fetchGoogleReviews().then((response) => {
        testimonials.value = response;
    });
};

onBeforeMount(() => {
  window.addEventListener("resize", checkIsMobile);
});
onMounted(() => {
  checkIsMobile();
    getGoogleReviews();
    const thumbsSplide = thumbnailsslider.value?.splide;
      if ( thumbsSplide ) {
        mainslider.value?.sync( thumbsSplide );
      }
});

const sliderOptions = {
    perPage: 1,
    perMove   : 1,
    autoplay: false,
    pagination: false,
    arrows: false,
    padding: '0px',
    focus  : 'center',
    direction: 'ttb',
    height   : '480px',
};
const sliderOptionsThumbnails = {
    direction: 'ttb',
    height   : '280px',
    type        : 'slide',
    rewind      : true,
    perPage     : 4,
    gap         : '0',
    pagination  : false,
    arrows: false,
    fixedWidth  : 70,
    fixedHeight : 70,
    cover       : true,
    focus       : 'center',
    isNavigation: true,
    updateOnMove: true,
};
const sliderOptionsMobile = {
    arrowPath: 'M40.9782 16.6354L25.2042 0.861441C24.8137 0.470916 24.1805 0.470916 23.79 0.861441C23.3995 1.25197 23.3995 1.88513 23.79 2.27565L37.8574 16.343L2.41398 16.343C1.8617 16.343 1.41398 16.7907 1.41398 17.343C1.41398 17.8953 1.8617 18.343 2.41398 18.343H37.8564L23.79 32.4094C23.3995 32.8 23.3995 33.4331 23.79 33.8236C24.1805 34.2142 24.8137 34.2142 25.2042 33.8236L40.9525 18.0753C41.1488 17.8927 41.2716 17.6322 41.2716 17.343C41.2716 17.0668 41.1596 16.8168 40.9786 16.6358C40.9784 16.6357 40.9783 16.6356 40.9782 16.6354Z',
    rewind: true,
    type: 'slide',
    perPage: 1,
    autoplay: true,
    pagination: false,
    arrows: true,
    padding: '0px',
    focus  : 'center',
}

const setAltImg = (event) => {
    event.target.src = 'https://res.cloudinary.com/ilovekickboxing/image/upload/v1/CMS_Site/review-img.png';
}
</script>

<template>
    <template v-if="isMobile">
        <SectionContainer>
            <ElementContaier
                class="testimonial-slider"
            >
                <Splide 
                    :options="sliderOptionsMobile"
                >
                    <SplideSlide
                            v-for="(testimonial) in testimonials"
                            :key="testimonial.schools_google_reviews_id"
                    >
                        <div class="testimonial_box">
                            <div class="testimonial_card">
                                <div class="testimonial_avatar">
                                    <img :src="testimonial.author_img_url" :alt="testimonial.author">
                                </div>
                                <div class="testimonial_desc">
                                    {{ testimonial.text }}
                                </div>
                                <div class="testimonial_by">
                                    <Text
                                        type="H4"
                                        :text="testimonial.author"
                                    />
                                    <Text
                                        :text="testimonial.location.Name"
                                    />
                                </div>
                            </div>
                        </div>
                    </SplideSlide>
                </Splide>
            </ElementContaier>
        </SectionContainer>
    </template>
    <template v-else>
        <SectionContainer widthSize="80">
            <ElementContaier class="testimonial-slider">
                <Splide 
                    :options="sliderOptions"
                    ref="mainslider"
                >
                    <SplideSlide
                        v-for="(testimonial) in testimonials"
                        :key="testimonial.schools_google_reviews_id"
                    >
                        <div class="testimonial_box">
                            <div class="testimonial_card">
                                <div class="testimonial_desc">
                                    {{ testimonial.text }}
                                </div>
                                <div class="testimonial_by">
                                    <Text 
                                        type="H4" 
                                        :text="testimonial.author"
                                    />
                                    <Text 
                                        :text="testimonial.location.Name"
                                    />
                                </div>
                            </div>
                        </div>
                    </SplideSlide>                                    
                </Splide>
            </ElementContaier>
        </SectionContainer>
        <SectionContainer 
            widthSize="20"
            class="justify-content-center"
        >
            <ElementContaier
                class="testimonial-thumbnails"
            >
                <Splide 
                    :options="sliderOptionsThumbnails"
                    ref="thumbnailsslider"
                >
                    <SplideSlide
                        v-for="(testimonial, index) in testimonials"
                        :key="testimonial.schools_google_reviews_id"
                    >
                        <div :class="'testimonial_thumbnail tt-' + index + 1">
                            <div class="testimonial_avatar_thumbnail">
                                <Image 
                                    :src="testimonial.author_img_url" 
                                    :text="testimonial.author" 
                                    @error="setAltImg"
                                />
                            </div>
                        </div>
                    </SplideSlide>                                    
                </Splide>
            </ElementContaier>
        </SectionContainer>                 
    </template>
</template>