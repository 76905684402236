<script setup>
import Text from "../elements/Text.vue";
import { defineProps, onMounted } from "vue";
import SectionContainer from "../containers/SectionContainer.vue";
import ElementContaier from "../containers/ElementContaier.vue";
import { loadScript } from "vue-plugin-load-script";

defineProps({
  currentStudio: {
    type: Object,
  },
});

onMounted(() => {
  loadScript("https://widgets.mindbodyonline.com/javascripts/healcode.js");
});
</script>

<template>
  <SectionContainer>
    <ElementContaier class="studio-service">
      <Text
        type="H2"
        text="Memberships Plans"
        class="element-text text-align-left font-size-64"
      />
    </ElementContaier>
    <ElementContaier class="studio-service-list">
      <template
        v-for="(contract, index) in currentStudio.mbContracts"
        :key="index"
      >
        <div class="service-box service-box-center">
          <div class="service-card">
            <div class="service-content">
              <Text type="H4" :text="contract.Name" class="service-title" />
              <h3 class="service-price">
                <span class="currency">$</span
                >{{ contract.RecurringPaymentAmountTotal }}
              </h3>
              <healcode-widget
                data-version="0.2"
                data-link-class="healcode-contract-text-link"
                :data-site-id="currentStudio.mindbody_web_id"
                :data-mb-site-id="currentStudio.mindbody_site_id"
                data-type="contract-link"
                data-inner-html="<button type='button' class='btn btn-primary'>Buy Now</button>"
                :data-service-id="contract.Id"
              />
            </div>
          </div>
        </div>
      </template>
    </ElementContaier>
  </SectionContainer>
</template>
