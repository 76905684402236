<script setup>
    import {defineProps, onMounted} from "vue";
    import HtmlComponent from "./HtmlComponent.vue";
    import {loadScript} from "vue-plugin-load-script";

    defineProps({
        widget: {
            type: String
        },
    });

onMounted(() => {
   loadScript("https://widgets.mindbodyonline.com/javascripts/healcode.js");
});
</script>
<template>
    <div class="schedule-widget">
        <HtmlComponent :rawHtml="widget" />
    </div>
</template>