<script setup>
import { defineProps, reactive, onMounted, ref, computed } from "vue";
//import CustomSection from "./CustomSection.vue";
import Carousel from "../elements/Carousel.vue";
import CustomModule from "@/components/modules/CustomModule.vue";
import VideoPlayer from "../elements/VideoPlayer.vue";

const props = defineProps({
  sections: {
    type: Object,
  },
  currentStudio: {
    type: Object,
  },
});

const carouselImages = ref([]);
const sortedSections = ref([]);

const status = computed(() => props.currentStudio?.status ?? null);
const openingMessage = computed(() => props.currentStudio?.opening_message ?? '');

// Section sorting

onMounted(() => {
  reactive(props.sections).sort((a, b) => a.section_order - b.section_order);
  reactive(props.sections).filter((item) => {
    if (item.section_size === 0) {
      carouselImages.value.push(item.moduleSectionElements[0]);
    } else {
      sortedSections.value.push(item);
    }
  });
});
</script>
<template>
  <div class="hero-main">
    <template v-if="carouselImages.length > 0">
      <template v-for="(section, index) in carouselImages" :key="index">
        <div
          class="carouselImages"
          :class="
            section.element_type == 'BACKGROUND_VIDEO'
              ? 'video-banner-main'
              : ''
          "
        >
          <template v-if="section.element_type == 'CAROUSEL'">
            <Carousel :images="section.images" />
          </template>
          <template v-else-if="section.element_type == 'BACKGROUND_VIDEO'">
            <div class="video-Banner">
              <VideoPlayer
                :src="section.element_source"
                width="1500"
                height="600"
              />
            </div>
          </template>
        </div>
      </template>
    </template>
    <div class="sortedSections" v-if="sortedSections.length > 0">
      <div v-if="status === 2">
        <div class="element-contaier">
          <h1 class="element-text font-size-65">{{ openingMessage }}</h1>
        </div>
      </div>
      <CustomModule
        :sections="sortedSections"
        :currentStudio="currentStudio"
        :IsheroBaner="true"
      />
    </div>
  </div>
</template>
