<script setup>

</script>

<template>
    <div class="fighter-wrp">
        <div class="fighter-main">
            <div class="fighter-img fighter_left">
                <img src="../../assets/challenge_img_left.png" alt="" class="img-fluid">
            </div>
            <div class="fighter-img fighter_right">
                <img src="../../assets/challenge_img_right.png" alt="" class="img-fluid">
            </div>
        </div>
    </div>
</template>