<script setup>
import {onBeforeMount, defineProps, onMounted, ref} from "vue";
import '../../assets/scss/locationMap.scss'
import {GoogleMap, Marker, InfoWindow} from 'vue3-google-map';
import Text from "@/components/elements/Text.vue";
import {fetchSchoolBio} from "@/mixins/api";
import SectionContainer from "@/components/containers/SectionContainer";

const props = defineProps({
  currentStudio: {
    type: Object
  },
});

const schoolBio = ref({});


// Section sorting
const mapLocation = {
  lat: parseFloat(props.currentStudio?.lat),
  lng: parseFloat(props.currentStudio?.lng),
};

const GOOGLE_API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;

const getSchoolBio = () => {
  fetchSchoolBio('school', props.currentStudio?.ID).then((response) => {
    schoolBio.value = response[0];
  });
};
const isMobile = ref(false);
const checkIsMobile = () => {
  let width = window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
  isMobile.value = width < 640 ? true : false;
};

onBeforeMount(() => {
  window.addEventListener("resize", checkIsMobile);
});
onMounted(() => {
  checkIsMobile();
  getSchoolBio();
});

</script>
<template>
  <div class="row">
    <SectionContainer widthSize="50" class="location-image mb-16" v-if="!isMobile && schoolBio?.image_link">
      <img :src="schoolBio?.image_link" alt="" style="width: 100%; height: 100%" />
    </SectionContainer>
    <SectionContainer :widthSize="schoolBio?.image_link ? '50' : '100'" class="google-map mb-16">
      <div class="google-map-main">
        <GoogleMap
            :api-key="GOOGLE_API_KEY"
            style="width: 100%; height: 430px"
            :center="mapLocation"
            :zoom="15"
        >
          <Marker :options="{ position: mapLocation }">
            <InfoWindow>
              <div class="map-info-window">
                <Text class="map-info-window-title" type="H5"
                      :text="currentStudio.City + ', ' + currentStudio?.state?.Code"/>
                <Text class="map-info-window-paragraph" :text="currentStudio.Line1"/>
                <Text class="map-info-window-paragraph"
                      :text="currentStudio.City + ', ' + currentStudio?.state?.Code + ' ' + currentStudio?.ZipCode"/>
                <Text class="map-info-window-paragraph" :text="currentStudio.phone"/>
              </div>
            </InfoWindow>
          </Marker>
        </GoogleMap>
      </div>
    </SectionContainer>
  </div>
</template>