<script setup>
import { defineProps } from 'vue'
import PlayIcon from '../../assets/svg/PlayIcon.vue';
  const props = defineProps({
    text: String,
    class: String,
    styled: String,
    type: { type: String, required: false, default: 'button' },
  })
</script>

<template>
    <button :type="props.type" :class="props.class" class="btn btn-light btn-outline" v-if="props.styled === 'bordered'">
        {{props.text}}
    </button>
    <button :type="props.type" :class="props.class" class="btn btn-primary btn-outline" v-else-if="props.styled === 'bordered-primary'">
        {{props.text}}
    </button>
    <button :type="props.type" :class="props.class" class="btn btn-secondary" v-else-if="props.styled === 'secondary'">
        {{props.text}}
    </button>
    <button :type="props.type" :class="props.class" class="btn btn-dark" v-else-if="props.styled === 'dark'">
        {{props.text}}
    </button>
    <button :type="props.type" :class="props.class" class="btn-link" v-else-if="props.styled === 'link'">
        {{props.text}}
    </button>
    <button :type="props.type" :class="props.class" class="btn btn-view" v-else-if="props.styled === 'view'">
        {{props.text}}
    </button>
    <button :type="props.type" :class="props.class" class="btn btn-circle" v-else-if="props.styled === 'circle'">
        <span class="playicon">
            <PlayIcon />
        </span>
        {{props.text}}
    </button>
    <button :type="props.type" :class="props.class" class="btn btn-primary" v-else>
        <slot name="icon"></slot>
        {{props.text}}
    </button>
</template>
