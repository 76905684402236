<script setup>
import { computed, ref, defineProps } from 'vue'
import CloseIcon from '../../assets/svg/CloseIcon.vue';
import Image from './Image.vue';
import Modal from './Modal.vue';

  const props = defineProps({
    src: String,
    text: String,
    videoSource: String,
    width: {
      type: String,
      default: '1920',
    },
    height: {
      type: String,
      default: '1080',
    },
  })

    const isModalVisible = ref(false);
    const showModal = () => {
        isModalVisible.value = true;
    };
    const closeModal = () => {
        isModalVisible.value = false;
    };
    // Element Source
const elementSource = computed(() => {
  let youtubeShortSource = 'https://youtu.be/';
  let youtubeEmbedSource = 'https://www.youtube.com/embed/';
  return (props.videoSource) ? props.videoSource.replace(youtubeShortSource, youtubeEmbedSource) : '';
});
</script>

<template>
    <div class="video-iframe-card">
        <div class="vp-poster">
            <div class="img-box">
                <Image
                    :src="text"
                    :text="text"
                />
            </div>
            <button type="button" @click="showModal" class="btn btn-dark btn-play">
                <svg width="21" height="30" viewBox="0 0 21 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.1955 14.6945L0.328125 0.867432V29.2494L20.1955 14.6945Z" fill="currentColor"/></svg>
            </button>
        </div>
        <Modal v-if="isModalVisible">
            <template #body>
                <span class="modal-close" @click="closeModal">
                    <CloseIcon />
                </span>
                <div class="video-iframe-video">
                    <div class="video-iframe-box">
                        <iframe 
                            :width="width" 
                            :height="height"
                            :src="elementSource+'?controls=0&showinfo=0&rel=0&autoplay=0&loop=1&mute=0'"
                            :title="text" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowfullscreen
                        >
                        </iframe>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>