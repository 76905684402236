<script setup>
    import { defineProps } from "vue";
import '../../assets/scss/modal.scss'
const props = defineProps({
    modalLarge: String,
    modalSmall: String,
})
</script>

<template>
    <Transition name="modal-fade">
        <div class="modal-backdrop">
            <div class="modal">
                <div 
                    class="modal-dialog" 
                    :class="props.modalLarge ? 'modal-large' : props.modalSmall ? 'modal-small' : '' "
                >
                    <div class="modal-content">
                        <div class="modal-header">
                            <slot name="header"></slot>
                        </div>
                        <div class="modal-body">
                            <slot name="body">
                                This is the default body!
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>