<script setup>
    import { Form, Field } from "vee-validate";
    import * as Yup from "yup";
    import {defineProps, onBeforeMount, onMounted, ref,computed} from "vue";
    import SectionContainer from "../containers/SectionContainer.vue";
    import Text from "./Text.vue";
    import Button from "./Button.vue";
    import ElementContaier from "../containers/ElementContaier.vue";
    import FindStudioSearch from "./FindStudioSearch.vue";
    import { KlaviyotrackEvent, EmailIntroOfferVisit, getfreeclassByStudioId,getProductsByStudioId  } from "../../mixins/api";
    import {useRoute, useRouter} from "vue-router";
    import { triggerTrackEvents } from "../../mixins/events_handler";

    const route = useRoute();
    const queryParams = route.query;
    const router = useRouter();
    const isMobile = ref(false);
    const FreeclassUrlStarted = process.env.VUE_APP_FreeclassUrl_URL;
    const freeclass = ref([]);
    const Webspecialclass = ref([]);
    const checkIsMobile = () => {
        let width = window.innerWidth || 
        document.documentElement.clientWidth || 
        document.body.clientWidth;
        isMobile.value = width < 640 ? true : false;
    };
    onBeforeMount(() => {
        window.addEventListener("resize", checkIsMobile);
    });
    const getfreeclassonStarted = () => {
        console.log('currentStudio', props?.currentStudio, studioId.value)
        var studioid = 0;
        if(studio?.value !=null && studio?.value?.ID>0)
    {
        studioid = studio?.value?.ID;
    }
    else if(props?.currentStudio != null && props?.currentStudio?.ID>0){
        studioid = props?.currentStudio?.ID;
    }
            getfreeclassByStudioId(studioid).then((response) => {
          freeclass.value = response;
        });
    };
    const getWebspecialclassonStarted = () => {
        console.log('currentStudio', props?.currentStudio, studioId.value)
        var studioid = 0;
        if(studio?.value !=null && studio?.value?.ID>0)
    {
        studioid = studio?.value?.ID;
    }
    else if(props?.currentStudio != null && props?.currentStudio?.ID>0){
        studioid = props?.currentStudio?.ID;
    }
        getProductsByStudioId(studioid).then((response) => {
            Webspecialclass.value = response;
           
        });
    };
     // Current Studio Id
     const studioId = computed(() => {
        return props?.currentStudio?.ID
            ? props?.currentStudio?.ID
            : 0;
    });
    onMounted(() => {
        checkIsMobile();
        getfreeclassonStarted();
        getWebspecialclassonStarted();
        studio.value = props.currentStudio
    });
    const props = defineProps({
      redirectedToCheckout: Boolean,
      redirectedToStudio: Boolean,
      currentStudio: {
        type: Object
    }
    })

    const schema = Yup.object().shape({
        firstname: Yup.string().required("First name is required"),
        lastname: Yup.string().required("Last name is required"),
        email: Yup.string().required("Email is required").email("Email is invalid"),
        phonenumber: Yup.string().required("Phone number is required"),
    });
    const customer = ref({
        firstname: "",
        lastname: "",
        email: sessionStorage.getItem('email') || "",
        phonenumber: "",
    });
    const containsFreeClass  = (productname) => {
        const mainStringLower = productname.toLowerCase();
        return mainStringLower.includes("free")

        };
    const studio = ref();
    const checkoutUrl = process.env.VUE_APP_CHECKOUT_URL;
    const onSubmit = () => {
        if(studio.value?.IsActive== false){
            alert("This location does not offer any paid web specials at this time.");
            return;
        }
        if(!props.currentStudio && (props.currentStudio?.IsActive==false)){
            alert("This location does not offer any paid web specials at this time.");
            return;
        }
        if(studio.value?.ID) {
          if(!props.currentStudio && !props.currentStudio?.ID) {
            triggerTrackEvents('Home', 'Find Studio Popup', 'home_optin')
          } else {
            triggerTrackEvents('Studio', 'Find Studio Popup', 'optin_lead')
          }
        try{
          EmailIntroOfferVisit(customer.value, studio.value).then( () => {
            });
        }
        catch(exception)
        {}
      try{
            KlaviyotrackEvent(customer.value, studio.value).then( () => {
              const params = {
                ...queryParams,
                fname: customer.value.firstname,
                lname: customer.value.lastname,
                email: customer.value.email,
                phone: customer.value.phonenumber
              }
              if(props.redirectedToCheckout ) {
                var url =redirectTogetfreeclassUrl();
                if(url!=""){
                    window.location.href = url;
                }
                else if(WebspecialExists()){
                const paramsQuery = Object
                    .keys(params)
                    .map(value => `${value}=${encodeURIComponent(params[value])}`)
                    .join('&');
                window.location.href = checkoutUrl + studio.value.url_slug +
                    '?' + paramsQuery;
                }
                else if(!(redirectTogetfreeclassUrl())&& !WebspecialExists()){
                    var url_slug_loc = "";
                    if(studio.value!= null && studio.value?.url_slug != ""){
                        url_slug_loc = studio.value?.url_slug;
                    }
                    if(!props.currentStudio && (props.currentStudio?.IsActive==false && props.currentStudio?.url_slug != "")){
                        url_slug_loc = props.currentStudio?.url_slug;
                    }
                router.push({path: '/' +url_slug_loc})
                if(window.location.href.includes(url_slug_loc))
                {window.location.reload();}
                }
              } else {//if(props.redirectedToStudio) {
                if(!(redirectTogetfreeclassUrl())&& !WebspecialExists()){
                    var url_slug_loc = "";
                    if(studio.value!= null && studio.value?.url_slug != ""){
                        url_slug_loc = studio.value?.url_slug;
                    }
                    if(!props.currentStudio && (props.currentStudio?.IsActive==false && props.currentStudio?.url_slug != "")){
                        url_slug_loc = props.currentStudio?.url_slug;
                    }
                router.push({path: '/' +url_slug_loc})
                if(window.location.href.includes(url_slug_loc))
                {window.location.reload();}
                }
            //   } else {
            //     if(!(redirectTogetfreeclassUrl())){
            //     router.push({path: '/' + studio.value.url_slug + '/signup', query: params})
            //     }
          }
            });
        }
        catch(exception)
        {}
      
        } else {
            console.log('Select studio')
        }
    };

    const selectedStudio = (studioDetails) => {
        studio.value = studioDetails;
        getfreeclassonStarted();
        getWebspecialclassonStarted();
    }
    const WebspecialExists=()=> {
   
   
   if( Webspecialclass.value != undefined &&  Webspecialclass.value!= null ){
    if(Webspecialclass.value.length==0)
   {
    return false;
   }
        Webspecialclass.value.forEach(product => {
             if(product.category.toLowerCase()!='Web Special'.toLowerCase())
             {
                return false;
             }
            });
        }
        if(Webspecialclass.value == undefined ||  Webspecialclass.value== null || Webspecialclass.value.length==0 )
        {
            return false;
             }
        return true;
  }
    const redirectTogetfreeclassUrl=()=> {
   var url="";
   if(studio.value.landing_page_freeclass_sw==1 || studio.value.landing_page_freeclass_sw==true){
   if( freeclass.value != undefined &&  freeclass.value!= null ){
        freeclass.value.forEach(product => {
                if(containsFreeClass(product.type) && containsFreeClass(product.category))
                { var url_slug_loc = "";
                    if(studio.value!= null && studio.value?.url_slug != ""){
                        url_slug_loc = studio.value?.url_slug;
                    }
                    if(!props.currentStudio && (props.currentStudio?.IsActive==false && props.currentStudio?.url_slug != "")){
                        url_slug_loc = props.currentStudio?.url_slug;
                    }
                    url= (FreeclassUrlStarted + url_slug_loc) ;
                  
                }
            });
        }
    }
        return url;
  }
</script>

<template>
    <div class="row">
        <SectionContainer widthSize="50">
            <div class="getstarted-left">
                <div class="getstarted-left-top">
                    <ElementContaier>
                        <Text type="H4" text="Make today your Day One with iLoveKickboxing." />
                    </ElementContaier>
                    <ElementContaier>
                        <Text text="Stop waiting for tomorrow to kick off your health and wellness journey with us. Let’s get started." class="mb-24 font-size-15" />
                    </ElementContaier>
                    <template v-if="!currentStudio && !currentStudio?.ID">
                        <ElementContaier>
                            <Text type="H4" text="FIND YOUR STUDIO" class="mb-16" />
                        </ElementContaier>
                        <ElementContaier class="getstarted-findstudio">
                            <FindStudioSearch @selectedStudio="selectedStudio" />
                        </ElementContaier>
                    </template>
                </div>
                <template v-if="!isMobile">
                    <div class="getstarted-left-bottom">
                        <ElementContaier>
                            <Text text="By submitting this form, I agree to receive emails, text messages, and other communications regarding offers and promotions from iLoveKickboxing." class="mb-10 font-size-15" />
                        </ElementContaier>
                    </div>
                </template>
            </div>
        </SectionContainer>
        <SectionContainer widthSize="50">
            <ElementContaier>
                <Text 
                    type="H4" 
                    text="We’d love to know a little bit more about you."
                    class="mb-24"
                />
            </ElementContaier>
            <ElementContaier>
                <Form
                    @submit="onSubmit"
                    :validation-schema="schema"
                    v-slot="{ errors }"
                >
                    <div class="form-group">
                        <Field
                            type="text"
                            v-model="customer.firstname"
                            name="firstname"
                            id="firstname"
                            class="form-control"
                            placeholder=""
                            :class="{ 'is-invalid': errors.firstname }"
                        />
                        <label>First Name</label>
                        <div class="invalid-feedback">{{ errors.firstname }}</div>
                    </div>
                    <div class="form-group">
                        <Field
                            type="text"
                            v-model="customer.lastname"
                            name="lastname"
                            id="lastname"
                            class="form-control"
                            placeholder=""
                            :class="{ 'is-invalid': errors.lastname }"
                        />
                        <label>Last Name</label>
                        <div class="invalid-feedback">{{ errors.lastname }}</div>
                    </div>
                    <div class="form-group">
                        <Field
                            type="text"
                            v-model="customer.email"
                            name="email"
                            id="email"
                            class="form-control"
                            placeholder=""
                            :class="{ 'is-invalid': errors.email }"
                        />
                        <label>Email</label>
                        <div class="invalid-feedback">{{ errors.email }}</div>
                    </div>
                    <div class="form-group">
                        <Field
                            type="text"
                            v-model="customer.phonenumber"
                            name="phonenumber"
                            id="phonenumber"
                            class="form-control"
                            placeholder=""
                            :class="{ 'is-invalid': errors.phonenumber }"
                        />
                        <label>Phone Number</label>
                        <div class="invalid-feedback">{{ errors.phonenumber }}</div>
                    </div>
                    <Button type="submit" text="Let's Do This!" class="col-100" />
                </Form>
                <template v-if="isMobile">
                    <ElementContaier>
                        <Text text="By submitting this form, I agree to receive emails, text messages, and other communications regarding offers and promotions from iLoveKickboxing." class="mt-24 mb-10 font-size-15" />
                    </ElementContaier>
                </template>
            </ElementContaier>
        </SectionContainer>
    </div>
</template>